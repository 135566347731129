import React from "react";
import { Route, Routes } from "react-router-dom";
import PrivacyAndPolicy from "./privacyAndPolicy";
import TermsConditions from "./termsConditions";

const Privacy = () => {
  return (
    <Routes>
      <Route path="/privacy_policies" element={<PrivacyAndPolicy />} />
      <Route path="/terms_conditions" element={<TermsConditions />} />
    </Routes>
  );
};

export default Privacy;
