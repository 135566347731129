import Index from "../views/dashboard/index";
import SignIn from "../views/dashboard/auth/sign-in";
import Default from "../layouts/dashboard/default";
import SignUp from "../views/dashboard/auth/sign-up";
import ConfirmMail from "../views/dashboard/auth/confirm-mail";
import LockScreen from "../views/dashboard/auth/lock-screen";
import ForgotPassword from "../views/dashboard/auth/forgot-password";
import UserProfile from "../views/dashboard/app/user-profile";
import UserAdd from "../views/dashboard/app/user-add";
import UserList from "../views/dashboard/app/user-list";
import UserLayout from "../components/common/layouts/UserLayout";
import ResetPassword from "../views/dashboard/auth/reset-password";
import AddPage from "../views/dashboard/app/addPage";
import VerifyEmail from "../views/dashboard/auth/verifyEmail";
import VerifyLogin from "../views/dashboard/auth/verifyLogin";
import ChangePassword from "../views/dashboard/app/changePassword";
import Home from "../views/dashboard/pages/home";
import Services from "../views/dashboard/pages/services";
import Contact from "../views/dashboard/pages/contact";
import About from "../views/dashboard/pages/about";
import Header from "../views/dashboard/pages/navigation/header";
import Footer from "../views/dashboard/pages/navigation/footer";
import Privacy from "../views/dashboard/pages/privacyPolicy";
import ServiceDetails from "../views/dashboard/pages/serviceDetails";
import SignInSection from "../views/dashboard/pages/signInSection/siginIn";
import ForgotPassSection from "../views/dashboard/pages/forgotPassword/forgotPassSection";
import SignUpSection from "../views/dashboard/pages/signUpSection/signUpSection";
const VisitorLayoutFunc = (comp) => {
  return <UserLayout>{comp}</UserLayout>;
};
const routes = [
  // Auth routes
  {
    path: "/",
    element: <SignIn />,
    protected: false,
    role: [],
  },
  {
    path: "/auth/sign-in",
    element: <SignIn />,
    protected: false,
    role: [],
  },
  {
    path: "/auth/verify-email/login",
    element: <VerifyLogin />,
    protected: false,
    role: ["admin"],
  },
  {
    path: "/dashboard",
    element: VisitorLayoutFunc(<Default />),
    protected: true,
    role: ["admin"],
  },
  {
    path: "/auth/sign-up",
    element: <SignUp />,
    protected: false,
    role: [],
  },
  {
    path: "/auth/confirm-mail",
    element: <ConfirmMail />,
    protected: false,
    role: [],
  },
  {
    path: "/auth/lock-screen",
    element: <LockScreen />,
    protected: false,
    role: [],
  },
  {
    path: "/auth/forgot-password",
    element: <ForgotPassword />,
    protected: false,
    role: [],
  },
  {
    path: "/auth/verify-email",
    element: <VerifyEmail />,
    protected: false,
    role: [],
  },
  {
    path: "/auth/reset-password",
    element: <ResetPassword />,
    protected: false,
    role: [],
  },
  {
    path: "dashboard/app/user-profile",
    element: VisitorLayoutFunc(<UserProfile />),

    protected: true,
    role: ["admin"],
  },
  {
    path: "dashboard/app/user-add",
    element: VisitorLayoutFunc(<UserAdd />),
    protected: true,
    role: ["admin"],
  },
  {
    path: "dashboard/app/user-list",
    element: VisitorLayoutFunc(<UserList />),
    protected: true,
    role: ["admin"],
  },
  {
    path: "dashboard/app/add-page",
    element: VisitorLayoutFunc(<AddPage />),
    protected: true,
    role: ["admin"],
  },
  {
    path: "dashboard/app/change-password",
    element: VisitorLayoutFunc(<ChangePassword />),
    protected: true,
    role: ["admin"],
  },
  {
    path: "dashboard/app/signInSection/*",
    element: VisitorLayoutFunc(<SignInSection />),
    protected: true,
    role: ["admin"],
  },
  {
    path: "dashboard/app/forgotPasswordSection/*",
    element: VisitorLayoutFunc(<ForgotPassSection />),
    protected: true,
    role: ["admin"],
  },
  {
    path: "dashboard/app/SignUpSection/sign_up",
    element: VisitorLayoutFunc(<SignUpSection />),
    protected: true,
    role: ["admin"],
  },
  {
    path: "dashboard/app/home/*",
    element: VisitorLayoutFunc(<Home />),
    protected: true,
    role: ["admin"],
  },
  {
    path: "dashboard/app/about_us/*",
    element: VisitorLayoutFunc(<About />),
    protected: true,
    role: ["admin"],
  },
  {
    path: "dashboard/app/contact/*",
    element: VisitorLayoutFunc(<Contact />),
    protected: true,
    role: ["admin"],
  },
  {
    path: "dashboard/app/service/*",
    element: VisitorLayoutFunc(<Services />),
    protected: true,
    role: ["admin"],
  },
  {
    path: "dashboard/app/service_details/*",
    element: VisitorLayoutFunc(<ServiceDetails />),
    protected: true,
    role: ["admin"],
  },
  {
    path: "dashboard/app/header",
    element: VisitorLayoutFunc(<Header />),
    protected: true,
    role: ["admin"],
  },
  {
    path: "dashboard/app/footer",
    element: VisitorLayoutFunc(<Footer />),
    protected: true,
    role: ["admin"],
  },
  {
    path: "dashboard/app/privacy/*",
    element: VisitorLayoutFunc(<Privacy />),
    protected: true,
    role: ["admin"],
  },
];
export default routes;
