import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Image,
  Form,
  DropdownButton,
  Dropdown,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import Card from "../../../components/Card";

// img
import shap1 from "../../../assets/images/shapes/01.png";
import shap2 from "../../../assets/images/shapes/02.png";
import shap3 from "../../../assets/images/shapes/03.png";
import shap4 from "../../../assets/images/shapes/04.png";
import shap5 from "../../../assets/images/shapes/05.png";
import shap6 from "../../../assets/images/shapes/06.png";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  approvedUserList,
  getUserDetails,
  getUserList,
} from "../../../services/api/usersApi";
import {
  startLoading,
  stopLoading,
} from "../../../store/reducers/loadingSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  clearUserList,
  updateUserList,
} from "../../../store/reducers/userListSlice";
import { debounce } from "lodash";
import { toast } from "react-toastify";
import ApprovedUser from "../../../components/common/approvedUser/approvedUser";

const UserList = () => {
  const dispatch = useDispatch();
  const { userList } = useSelector((state) => state.users);
  // const [userGetPage, setUserGetPage] = useState([]);
  const [current_page, setCurrentPage] = useState(0);
  const [userGetTotalPage, setUserGetTotalPage] = useState([]);
  const [userStatus, setUserStatus] = useState("");
  const [dropDown, setDropDown] = useState("Select");
  const [userSearch, setUserSearch] = useState("");
  const [showApprovedModal, setShowApprovedModal] = useState(false);
  const [userId, setUserId] = useState("");
  const [userFirstName, setUserFirstName] = useState("");
  const [userLastName, setUserLastName] = useState("");

  useEffect(() => {
    (async () => {
      let userList = await getUserList({ current_page: 1, limit: 10 });
      dispatch(updateUserList(userList?.data?.users));
      // setUserGetPage(userList?.data?.page);
      setCurrentPage(userList?.data?.page);
      // setUserGetPage(Array(userList?.data?.page).fill("page"));
      setUserGetTotalPage(Array(userList?.data?.totalPages).fill("totalPages"));
    })();
    return () => {
      // setUserGetPage(0);
      dispatch(clearUserList());
      setUserStatus("");
      setUserSearch("");
    };
  }, []);
  // useEffect(()=>{
  //   setUserGetPage(Array(Math.ceil(userList.length / 10)).fill("page"));
  // },[userList])
  useEffect(() => {
    dispatch(startLoading());
    setTimeout(() => {
      dispatch(stopLoading());
    }, 1000);
  }, []);
  const loadMoreData = async (page) => {
    let userList = await getUserList({
      current_page: page,
      limit: 10,
      status: userStatus,
      search_value: userSearch,
    });
    setCurrentPage(userList?.data?.page);
    dispatch(updateUserList(userList?.data?.users));
    // setUserGetPage(Array(userList?.data?.page).fill("page"));
  };
  const approveHandler = async () => {
    const approvedList = await approvedUserList(userId);
    if (approvedList) {
      let newUserList = userList.map((item) => {
        if (item.id === userId) {
          return { ...approvedList?.data?.userDetails };
        }
        return item;
      });
      dispatch(updateUserList(newUserList));
      setShowApprovedModal(false);
      setUserId("");
      setUserFirstName("");
      setUserLastName("");
      toast.success(approvedList?.message);
    }
  };
  const userDetails = async (id) => {
    const userDetailsData = await getUserDetails(id);
  };
  const filterHandler = async (e) => {
    setDropDown(
      e === "" ? "Select" : e === "active" ? "Approved" : "Not Approved"
    );
    setUserStatus(e);
    let userListData = await getUserList({
      current_page: 1,
      limit: 10,
      status: e,
      search_value: userSearch,
    });
    setCurrentPage(userListData?.data?.page);
    setUserGetTotalPage(
      Array(userListData?.data?.totalPages).fill("totalPages")
    );
    // setUserGetPage(userListData?.data?.page);
    let filterUserList = userListData?.data?.users.filter((item) => {
      if (item.status === e) {
        return item;
      }
    });
    dispatch(updateUserList(filterUserList));
    if (e === "") {
      dispatch(updateUserList(userListData?.data?.users));
    }
  };
  const searchHandler = debounce(async (e) => {
    setUserSearch(e.target.value.trim());
    if (e.target.value !== "") {
      let userListData = userStatus
        ? await getUserList({
            current_page: 1,
            limit: 10,
            status: userStatus,
            search_value: e.target.value.trim(),
          })
        : await getUserList({
            current_page: 1,
            limit: 10,
            search_value: e.target.value.trim(),
          });
      // setUserGetPage(userListData?.data?.page);
      setCurrentPage(userListData?.data?.page);
      setUserGetTotalPage(
        Array(userListData?.data?.totalPages).fill("totalPages")
      );
      dispatch(updateUserList(userListData?.data?.users));
    } else {
      let userPreviousList = userStatus
        ? await getUserList({
            current_page: current_page,
            limit: 10,
            status: userStatus,
          })
        : await getUserList({
            current_page: current_page,
            limit: 10,
          });
      // setUserGetPage(userListData?.data?.page);
      setCurrentPage(userPreviousList?.data?.page);
      setUserGetTotalPage(
        Array(userPreviousList?.data?.totalPages).fill("totalPages")
      );
      dispatch(updateUserList(userPreviousList?.data?.users));
    }
  }, 500);
  const openApprovedModel = (id, firstName, lastName) => {
    setUserId(id);
    setUserFirstName(firstName);
    setUserLastName(lastName);
    setShowApprovedModal(true);
  };
  return (
    <>
      <div>
        <Row>
          <Col sm="12">
            <Card>
              <Card.Header className="d-flex justify-content-between">
                <div className="header-title">
                  <h4 className="card-title">User List</h4>
                </div>
                <div classname="col-md-6">
                  <div
                    classname="dataTables_length"
                    id="user-list-table_length"
                  >
                    {/* <label>
                      Status{" "}
                      <select
                        name="user-list-table_length"
                        aria-controls="user-list-table"
                        classname="form-select form-select-sm"
                        onChange={(e) => filterHandler(e)}
                      >
                        <option value="">Select</option>
                        <option value="active">Approved</option>
                        <option value="not_approved">Not Approved</option>
                      </select>{" "}
                    </label> */}
                    <DropdownButton id="dropdown-basic-button" title={dropDown}>
                      {(dropDown === "Approved" ||
                        dropDown === "Not Approved") && (
                        <Dropdown.Item onClick={() => filterHandler("")}>
                          Select
                        </Dropdown.Item>
                      )}
                      <Dropdown.Item onClick={() => filterHandler("active")}>
                        Approved
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => filterHandler("not_approved")}
                      >
                        Not Approved
                      </Dropdown.Item>
                    </DropdownButton>
                  </div>
                </div>
                <div classname="col-md-6">
                  <div
                    id="user-list-table_filter"
                    classname="dataTables_filter"
                  >
                    <Form>
                      <Row>
                        <Col lg="12">
                          <Form.Group className="form-group">
                            <Form.Control
                              type="search"
                              classname="form-control form-control-sm"
                              placeholder="Search by email or name..."
                              aria-describedby="email"
                              aria-controls="user-list-table"
                              onChange={(e) => searchHandler(e)}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </div>
              </Card.Header>
              <Card.Body className="px-0">
                <div className="table-responsive">
                  <table
                    id="user-list-table"
                    className="table table-striped"
                    role="grid"
                    data-toggle="data-table"
                  >
                    <thead>
                      <tr className="ligth">
                        <th>Profile</th>
                        <th>Name</th>
                        {/* <th>Contact</th> */}
                        <th>Email</th>
                        {/* <th>Country</th> */}
                        <th>Status</th>
                        {/* <th>Company</th> */}
                        <th>Join Date</th>
                        <th min-width="100px">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {userList &&
                        userList?.length > 0 &&
                        userList.map((item, idx) => (
                          <tr key={idx}>
                            <td className="text-center">
                              <Image
                                style={{ cursor: "pointer" }}
                                className="bg-soft-primary rounded img-fluid avatar-40 me-3"
                                src={
                                  item?.profile_image ||
                                  "/default-profile-pic.jpg"
                                }
                                alt="profile"
                              />
                            </td>
                            <td onClick={() => userDetails(item?.id)}>
                              {item?.first_name} {item?.last_name}
                            </td>
                            {/* <td>{item.phone}</td> */}
                            <td>{item?.email}</td>
                            {/* <td>{item.country}</td> */}
                            <td>
                              <span
                                className={`badge ${
                                  (item?.status === "active" && "bg-primary") ||
                                  (item?.status === "not_approved" &&
                                    "bg-warning") ||
                                  (item?.status === "Inactive" && "bg-danger")
                                }`}
                              >
                                {(item?.status === "active" && "Approved") ||
                                  (item?.status === "not_approved" &&
                                    "Not Approved")}
                              </span>
                            </td>
                            {/* <td>{item.company}</td> */}
                            <td>
                              {new Date(item?.createdAt).toLocaleDateString(
                                "en-US",
                                {
                                  day: "numeric",
                                  month: "long",
                                  year: "numeric",
                                }
                              )}
                            </td>
                            <td>
                              <div className="flex align-items-center list-user-action">
                                {item?.status !== "active" && (
                                  <Link
                                    className="btn btn-sm btn-icon btn-success"
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="Approve"
                                    data-original-title="Approve"
                                    to="#"
                                    onClick={() =>
                                      openApprovedModel(
                                        item?.id,
                                        item?.first_name,
                                        item?.last_name
                                      )
                                    }
                                  >
                                    <span className="btn-inner">
                                      <svg
                                        width="32"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          fillRule="evenodd"
                                          clipRule="evenodd"
                                          d="M9.87651 15.2063C6.03251 15.2063 2.74951 15.7873 2.74951 18.1153C2.74951 20.4433 6.01251 21.0453 9.87651 21.0453C13.7215 21.0453 17.0035 20.4633 17.0035 18.1363C17.0035 15.8093 13.7415 15.2063 9.87651 15.2063Z"
                                          stroke="currentColor"
                                          strokeWidth="1.5"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        ></path>
                                        <path
                                          fillRule="evenodd"
                                          clipRule="evenodd"
                                          d="M9.8766 11.886C12.3996 11.886 14.4446 9.841 14.4446 7.318C14.4446 4.795 12.3996 2.75 9.8766 2.75C7.3546 2.75 5.3096 4.795 5.3096 7.318C5.3006 9.832 7.3306 11.877 9.8456 11.886H9.8766Z"
                                          stroke="currentColor"
                                          strokeWidth="1.5"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        ></path>
                                        <path
                                          d="M19.2036 8.66919V12.6792"
                                          stroke="currentColor"
                                          strokeWidth="1.5"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        ></path>
                                        <path
                                          d="M21.2497 10.6741H17.1597"
                                          stroke="currentColor"
                                          strokeWidth="1.5"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        ></path>
                                      </svg>
                                    </span>
                                  </Link>
                                )}{" "}
                                {/* <Link className="btn btn-sm btn-icon btn-warning" data-toggle="tooltip" data-placement="top" title="Edit" data-original-title="Edit" to="#">
                                          <span className="btn-inner">
                                             <svg width="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M11.4925 2.78906H7.75349C4.67849 2.78906 2.75049 4.96606 2.75049 8.04806V16.3621C2.75049 19.4441 4.66949 21.6211 7.75349 21.6211H16.5775C19.6625 21.6211 21.5815 19.4441 21.5815 16.3621V12.3341" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                <path fillRule="evenodd" clipRule="evenodd" d="M8.82812 10.921L16.3011 3.44799C17.2321 2.51799 18.7411 2.51799 19.6721 3.44799L20.8891 4.66499C21.8201 5.59599 21.8201 7.10599 20.8891 8.03599L13.3801 15.545C12.9731 15.952 12.4211 16.181 11.8451 16.181H8.09912L8.19312 12.401C8.20712 11.845 8.43412 11.315 8.82812 10.921Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                <path d="M15.1655 4.60254L19.7315 9.16854" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                             </svg>
                                          </span>
                                       </Link>{' '} */}
                                {/* <Link
                                  className="btn btn-sm btn-icon btn-danger"
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title="Delete"
                                  data-original-title="Delete"
                                  to="#"
                                >
                                  <span className="btn-inner">
                                    <svg
                                      width="20"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                      stroke="currentColor"
                                    >
                                      <path
                                        d="M19.3248 9.46826C19.3248 9.46826 18.7818 16.2033 18.4668 19.0403C18.3168 20.3953 17.4798 21.1893 16.1088 21.2143C13.4998 21.2613 10.8878 21.2643 8.27979 21.2093C6.96079 21.1823 6.13779 20.3783 5.99079 19.0473C5.67379 16.1853 5.13379 9.46826 5.13379 9.46826"
                                        stroke="currentColor"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      ></path>
                                      <path
                                        d="M20.708 6.23975H3.75"
                                        stroke="currentColor"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      ></path>
                                      <path
                                        d="M17.4406 6.23973C16.6556 6.23973 15.9796 5.68473 15.8256 4.91573L15.5826 3.69973C15.4326 3.13873 14.9246 2.75073 14.3456 2.75073H10.1126C9.53358 2.75073 9.02558 3.13873 8.87558 3.69973L8.63258 4.91573C8.47858 5.68473 7.80258 6.23973 7.01758 6.23973"
                                        stroke="currentColor"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      ></path>
                                    </svg>
                                  </span>
                                </Link>{" "} */}
                              </div>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>

                {/* for page showing */}
                <div className="row align-items-center pageNumbers">
                  <div className="col-md-6 dataPerPage">
                    <div
                      className="dataTables_info"
                      id="datatable_info"
                      role="status"
                      aria-live="polite"
                    >
                      {userList && userList.length > 0
                        ? `Showing 1 to ${userList && userList.length} entries`
                        : "No users"}
                    </div>
                  </div>
                  <div className="col-md-6 dataPerPage">
                    <div
                      className="dataTables_paginate paging_simple_numbers"
                      id="datatable_paginate"
                    >
                      <ul className="pagination">
                        {current_page > 1 ? (
                          <li
                            className="paginate_button page-item previous disabled"
                            id="datatable_previous"
                            onClick={() => loadMoreData(current_page - 1)}
                            style={{ cursor: "pointer" }}
                          >
                            <a
                              href="#"
                              aria-controls="datatable"
                              data-dt-idx="previous"
                              tabindex="0"
                              className="page-link"
                            >
                              Previous
                            </a>
                          </li>
                        ) : (
                          <li></li>
                        )}

                        {userGetTotalPage &&
                          userGetTotalPage.map((i, index) => {
                            return (
                              <li
                                className={`paginate_button page-item ${
                                  current_page === index + 1 && "active"
                                }`}
                                onClick={() => loadMoreData(index + 1)}
                              >
                                <a
                                  href="#"
                                  aria-controls="datatable"
                                  data-dt-idx="0"
                                  tabindex="0"
                                  className="page-link"
                                >
                                  {index + 1}
                                </a>
                              </li>
                            );
                          })}
                        {userList.length > 9 ? (
                          <li
                            className="paginate_button page-item next disabled"
                            id="datatable_next"
                            onClick={() => loadMoreData(current_page + 1)}
                            style={{ cursor: "pointer" }}
                          >
                            <a
                              href="#"
                              aria-controls="datatable"
                              data-dt-idx="next"
                              tabindex="0"
                              className="page-link"
                            >
                              Next
                            </a>
                          </li>
                        ) : (
                          <li></li>
                        )}
                        {/* <li
                          className="paginate_button page-item previous disabled"
                          id="datatable_previous"
                        >
                          <a
                            href="#"
                            aria-controls="datatable"
                            data-dt-idx="previous"
                            tabindex="0"
                            className="page-link"
                          >
                            Previous
                          </a>
                        </li> */}

                        {/* <li
                          className="paginate_button page-item next disabled"
                          id="datatable_next"
                        >
                          <a
                            href="#"
                            aria-controls="datatable"
                            data-dt-idx="next"
                            tabindex="0"
                            className="page-link"
                          >
                            Next
                          </a>
                        </li> */}
                      </ul>
                    </div>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <ApprovedUser
          showApprovedModal={showApprovedModal}
          setShowApprovedModal={setShowApprovedModal}
          message={`Do you want to approve ${userFirstName} ${userLastName}?`}
          approveHandler={approveHandler}
        />
      </div>
    </>
  );
};

export default UserList;
