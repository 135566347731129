import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Image,
  Form,
  Button,
  ListGroup,
  FormControl,
  InputGroup,
  Card,
} from "react-bootstrap";

import { dynamicDataUpdateSectionVise } from "../../../services/api/dynamicDataSectionVise";
import { useDispatch } from "react-redux";
import {
  startLoading,
  stopLoading,
} from "../../../store/reducers/loadingSlice";

const CommonForm = ({ sectionId, data }) => {
  const [obj, setObj] = useState({});
  const dispatch = useDispatch();
  const submitHandler = async (e) => {
    dispatch(startLoading());
    let data = await dynamicDataUpdateSectionVise(sectionId, obj);
    if (data?.code === 200) {
      dispatch(stopLoading());
    }
  };

  useEffect(() => {
    setObj(data?.data?.pageContent?.text_fields);

    return () => setObj({});
  }, [data]);
  const onChangeHandler = (e, outerKey, innerKey) => {
    const newValue = e.target.value;
    setObj((prevState) => ({
      ...prevState,
      [outerKey]: {
        ...prevState[outerKey],
        [innerKey]: newValue,
      },
    }));
  };
  return (
    <>
      <section className="login-content">
        <Row className="m-0 align-items-center bg-white vh-100">
          <Col md="12" className="p-0">
            <Card className="card-transparent auth-card shadow-none d-flex justify-content-center mb-0">
              <Card.Body>
                <Form onSubmit={(e) => e.preventDefault()}>
                  {obj && (
                    <Row>
                      {Object.keys(obj).map((outerKey) => (
                        <>
                          {Object.entries(obj[outerKey]).map(
                            ([innerKey, value]) => (
                              <>
                                <Form.Label className="form-label">
                                  {innerKey}
                                </Form.Label>
                                <InputGroup>
                                  <Form.Control
                                    className="form-control"
                                    as="textarea"
                                    placeholder=""
                                    id={innerKey}
                                    name={innerKey}
                                    value={value}
                                    onChange={(e) =>
                                      onChangeHandler(e, outerKey, innerKey)
                                    }
                                  />
                                </InputGroup>
                              </>
                            )
                          )}
                        </>
                      ))}
                    </Row>
                  )}
                  <Button
                    onClick={submitHandler}
                    type="button"
                    className="btn-scroll"
                    variant="primary"
                  >
                    Update Details
                  </Button>
                </Form>
              </Card.Body>
            </Card>
            <div className="sign-bg sign-bg-right">
              <svg
                width="280"
                height="230"
                viewBox="0 0 431 398"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g opacity="0.05">
                  <rect
                    x="-157.085"
                    y="193.773"
                    width="543"
                    height="77.5714"
                    rx="38.7857"
                    transform="rotate(-45 -157.085 193.773)"
                    fill="#3B8AFF"
                  />
                  <rect
                    x="7.46875"
                    y="358.327"
                    width="543"
                    height="77.5714"
                    rx="38.7857"
                    transform="rotate(-45 7.46875 358.327)"
                    fill="#3B8AFF"
                  />
                  <rect
                    x="61.9355"
                    y="138.545"
                    width="310.286"
                    height="77.5714"
                    rx="38.7857"
                    transform="rotate(45 61.9355 138.545)"
                    fill="#3B8AFF"
                  />
                  <rect
                    x="62.3154"
                    y="-190.173"
                    width="543"
                    height="77.5714"
                    rx="38.7857"
                    transform="rotate(45 62.3154 -190.173)"
                    fill="#3B8AFF"
                  />
                </g>
              </svg>
            </div>
          </Col>
        </Row>
      </section>
    </>
  );
};

export default CommonForm;
