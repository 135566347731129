import React from "react";
const Logo = (props) => {
  return (
    <>
      <img
        width="30"
        className={`${props.color === true ? "text-primary" : ""}`}
        viewBox="0 0 30 30"
        fill="none"
        src="/fav.png"
      ></img>
    </>
  );
};

export default Logo;
