import React, { useEffect, useState } from "react";
import { dynamicDataSectionVise } from "../../../../services/api/dynamicDataSectionVise";
import { Tab, Tabs } from "react-bootstrap";
import ImageSection from "../imageSection";
import CommonForm from "../commonForm";

const SignInContents = () => {
    const [key, setKey] = useState();
  const [data, setData] = useState();
  const handleTabSelect = (selectedKey) => {
    setKey(selectedKey);
  };

  useEffect(() => {
    gettingDynamicData();
  }, []);
  const gettingDynamicData = async () => {
    let dataGet = await dynamicDataSectionVise("cms_section_sign_in");
    setData(dataGet);
  };
  return  <div className="tab-switch">
  <Tabs
    id="controlled-tab-example"
    activeKey={key}
    onSelect={handleTabSelect}
    className="mb-3"
  >
    <Tab eventKey="textSection" title="Text Section">
      <CommonForm sectionId={"cms_section_sign_in"} data={data} />
    </Tab>
    {data?.data?.pageContent?.image_fields && (
      <Tab eventKey="imageSection" title="Image Section">
        <ImageSection
          sectionId={"cms_section_sign_in"}
          data={data}
          gettingDynamicData={gettingDynamicData}
        />
      </Tab>
    )}
    {/* <Tab eventKey="contact" title="Contact" disabled>
    Tab content for Contact
  </Tab> */}
  </Tabs>
</div>
};

export default SignInContents;
