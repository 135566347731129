import React, { useEffect, useState } from "react";
import {
  changePassFormValidator,
  resetPassFormValidator,
} from "../../../services/formValidator/authValidator";
import { resetPassword } from "../../../services/api/authApis";
import { useFormik } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import {
  Row,
  Col,
  Image,
  Form,
  Button,
  ListGroup,
  FormControl,
  InputGroup,
  Card,
} from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import auth1 from "../../../assets/images/auth/01.png";
import { getChangePassword } from "../../../services/api/myprofileApis";

const ChangePassword = () => {
  const [oldShowPassword, setOldShowPassword] = useState(false);
  const [newShowPassword, setNewShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const changePassFormik = useFormik({
    initialValues: changePassFormValidator.initialValues,
    validationSchema: changePassFormValidator.validationSchema,
    onSubmit: async (values) => {
      let data = await getChangePassword({
        old_password: values.oldPassword,
        new_password: values.newPassword,
      });
      if (data.code === 200) {
        navigate("/dashboard");
      }
      //   navigate("/auth/sign-in");
    },
  });

  return (
    <>
      <section className="login-content">
        <Row className="m-0 align-items-center bg-white vh-100">
          {/* <Col
            md="6"
            className="d-md-block d-none bg-primary p-0 mt-n1 vh-100 overflow-hidden"
          >
            <Image
              src={auth1}
              className="img-fluid gradient-main animated-scaleX"
              alt="images"
            />
          </Col> */}
          <Col md="12" className="p-0">
            <Card className="card-transparent auth-card shadow-none d-flex justify-content-center mb-0">
              <Card.Body>
                {/* <Link to="/dashboard" className="navbar-brand d-flex align-items-center mb-3">
                      <svg width="30" className="text-primary" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                         <rect x="-0.757324" y="19.2427" width="28" height="4" rx="2" transform="rotate(-45 -0.757324 19.2427)" fill="currentColor" />
                         <rect x="7.72803" y="27.728" width="28" height="4" rx="2" transform="rotate(-45 7.72803 27.728)" fill="currentColor" />
                         <rect x="10.5366" y="16.3945" width="16" height="4" rx="2" transform="rotate(45 10.5366 16.3945)" fill="currentColor" />
                         <rect x="10.5562" y="-0.556152" width="28" height="4" rx="2" transform="rotate(45 10.5562 -0.556152)" fill="currentColor" />
                      </svg>
                      <h4 className="logo-title ms-3">BI Reports</h4>
                   </Link> */}
                <h2 className="mb-2">Change Password</h2>
                <Form onSubmit={changePassFormik.handleSubmit}>
                  <Row>
                    <Col lg="12" className="col-lg-12">
                      <Form.Group className="floating-label">
                        <Form.Label className="form-label">
                          Old Password
                        </Form.Label>
                        <InputGroup>
                          <Form.Control
                            className="form-control"
                            type={oldShowPassword ? "text" : "password"}
                            placeholder=""
                            id="oldPassword"
                            name="oldPassword"
                            value={changePassFormik?.values.oldPassword}
                            onChange={changePassFormik.handleChange}
                          />
                          <InputGroup.Text
                            onClick={() => setOldShowPassword((prev) => !prev)}
                            id="basic-addon1"
                          >
                            <FontAwesomeIcon
                              icon={oldShowPassword ? faEyeSlash : faEye}
                            />
                          </InputGroup.Text>
                        </InputGroup>
                        <p className="form-error-message">
                          {changePassFormik.touched.oldPassword &&
                            changePassFormik.errors.oldPassword}
                        </p>
                      </Form.Group>
                    </Col>
                    <Col lg="12" className="col-lg-12">
                      <Form.Group className="floating-label">
                        <Form.Label className="form-label">
                          New Password
                        </Form.Label>
                        <InputGroup>
                          <Form.Control
                            className="form-control"
                            type={newShowPassword ? "text" : "password"}
                            placeholder=""
                            id="newPassword"
                            name="newPassword"
                            value={changePassFormik?.values.newPassword}
                            onChange={changePassFormik.handleChange}
                          />
                          <InputGroup.Text
                            onClick={() => setNewShowPassword((prev) => !prev)}
                            id="basic-addon1"
                          >
                            <FontAwesomeIcon
                              icon={newShowPassword ? faEyeSlash : faEye}
                            />
                          </InputGroup.Text>
                        </InputGroup>
                        <p className="form-error-message">
                          {changePassFormik.touched.newPassword &&
                            changePassFormik.errors.newPassword}
                        </p>
                      </Form.Group>
                    </Col>
                    <Col lg="12" className="col-lg-12">
                      <Form.Group className="floating-label">
                        <Form.Label className="form-label">
                          Confirm Password
                        </Form.Label>
                        <InputGroup>
                          <Form.Control
                            className="form-control"
                            type={showConfirmPassword ? "text" : "password"}
                            placeholder=""
                            id="confrmPassword"
                            name="confrmPassword"
                            value={changePassFormik?.values.confrmPassword}
                            onChange={changePassFormik.handleChange}
                          />
                          <InputGroup.Text
                            onClick={() =>
                              setShowConfirmPassword((prev) => !prev)
                            }
                            id="basic-addon2"
                          >
                            <FontAwesomeIcon
                              icon={showConfirmPassword ? faEyeSlash : faEye}
                            />
                          </InputGroup.Text>
                        </InputGroup>
                        <p className="form-error-message">
                          {changePassFormik.touched.confrmPassword &&
                            changePassFormik.errors.confrmPassword}
                        </p>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Button type="submit" className="mt-3" variant="primary">
                    Change Password
                  </Button>
                </Form>
              </Card.Body>
            </Card>
            <div className="sign-bg sign-bg-right">
              <svg
                width="280"
                height="230"
                viewBox="0 0 431 398"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g opacity="0.05">
                  <rect
                    x="-157.085"
                    y="193.773"
                    width="543"
                    height="77.5714"
                    rx="38.7857"
                    transform="rotate(-45 -157.085 193.773)"
                    fill="#3B8AFF"
                  />
                  <rect
                    x="7.46875"
                    y="358.327"
                    width="543"
                    height="77.5714"
                    rx="38.7857"
                    transform="rotate(-45 7.46875 358.327)"
                    fill="#3B8AFF"
                  />
                  <rect
                    x="61.9355"
                    y="138.545"
                    width="310.286"
                    height="77.5714"
                    rx="38.7857"
                    transform="rotate(45 61.9355 138.545)"
                    fill="#3B8AFF"
                  />
                  <rect
                    x="62.3154"
                    y="-190.173"
                    width="543"
                    height="77.5714"
                    rx="38.7857"
                    transform="rotate(45 62.3154 -190.173)"
                    fill="#3B8AFF"
                  />
                </g>
              </svg>
            </div>
          </Col>
        </Row>
      </section>
    </>
  );
};

export default ChangePassword;
