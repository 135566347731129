import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  startLoading,
  stopLoading,
} from "../../../store/reducers/loadingSlice";

const AddPage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(startLoading());
    setTimeout(() => {
      dispatch(stopLoading());
    }, 1000);
  }, []);
  return <div>addPage</div>;
};

export default AddPage;
