export const objectTrimmer = (obj) => {
  for (let key in obj) {
    if (typeof obj[key] === "object") {
      objectTrimmer(obj[key]); // Recursively call the function for nested objects
    } else if (typeof obj[key] === "string") {
      obj[key] = obj[key].trim(); // Trim the string value
    }
  }
  return obj;
};
