import React, { useEffect, useState } from 'react'
import { dynamicDataSectionVise } from '../../../../services/api/dynamicDataSectionVise';
import { Tab, Tabs } from 'react-bootstrap';
import ImageSection from '../imageSection';
import CommonForm from '../commonForm';

const ForgotPasswordContents = () => {
    const [key, setKey] = useState();
    const [data, setData] = useState();
    const handleTabSelect = (selectedKey) => {
      setKey(selectedKey);
    };
  
    useEffect(() => {
      gettingDynamicData();
    }, []);
    const gettingDynamicData = async () => {
      let dataGet = await dynamicDataSectionVise("cms_section_forgot_password");
      setData(dataGet);
    };
  return (
    <div className="tab-switch">
  <Tabs
    id="controlled-tab-example"
    activeKey={key}
    onSelect={handleTabSelect}
    className="mb-3"
  >
    <Tab eventKey="textSection" title="Text Section">
      <CommonForm sectionId={"cms_section_forgot_password"} data={data} />
    </Tab>
    {data?.data?.pageContent?.image_fields && (
      <Tab eventKey="imageSection" title="Image Section">
        <ImageSection
          sectionId={"cms_section_forgot_password"}
          data={data}
          gettingDynamicData={gettingDynamicData}
        />
      </Tab>
    )}
    {/* <Tab eventKey="contact" title="Contact" disabled>
    Tab content for Contact
  </Tab> */}
  </Tabs>
</div>
  )
}

export default ForgotPasswordContents