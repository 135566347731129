import React from "react";
import CommonForm from "../commonForm";

const ServiceDetailsCompanies = () => {
  return (
    <>
      <CommonForm sectionId={"cms_section_service_serviceDetailsCompanies"} />
    </>
  );
};

export default ServiceDetailsCompanies;
