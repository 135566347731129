import React from "react";
import PoliciesCommonForm from "../policiesCommonForm";

const TermsConditions = () => {
  return (
    <>
      <PoliciesCommonForm sectionId={"cms_section_terms_and_conditions"} />
    </>
  );
};

export default TermsConditions;
