import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import {
  dynamicDataSectionVise,
  dynamicDataUpdateSectionViseImage,
} from "../../../services/api/dynamicDataSectionVise";
import {
  startLoading,
  stopLoading,
} from "../../../store/reducers/loadingSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import ImageCropper from "../../../components/common/ImageCropper/ImageCropper";
import imagesForErrorShow from "../../../assets/images/error/imagesForErrorShow.png";
const ImageComponent = ({
  image_field_key,
  obj,
  setObj,
  value,
  sectionId,
  gettingDynamicData,
}) => {
  const [selectedImages, setSelectedImages] = useState(null);
  const dispatch = useDispatch();
  const inputImgRef = useRef(null);

  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    // const imageUrls = [];
    if (file?.type?.includes("image")) {
      if (file?.size > 7340032) {
        toast.error("Image size should be less than 7MB");
      } else {
        setSelectedImages(file);
      }
    } else {
      toast.error("Unsupported file format");
    }
  };
  const changeImage = async (file) => {
    const formData = new FormData();
    formData.append("file", file);
    let bodyData = formData;
    dispatch(startLoading());
    let data = await dynamicDataUpdateSectionViseImage(
      sectionId,
      image_field_key,
      bodyData
    );
    if (data?.code === 200) {
      gettingDynamicData(sectionId);
      setSelectedImages(null);
      dispatch(stopLoading());
    }

    // for (let i = 0; i < files.length; i++) {
    //   const file = files[i];
    //   const reader = new FileReader();
    //   reader.onloadend = () => {
    //     imageUrls.push(reader.result);
    //     if (imageUrls.length === files.length) {
    //       setSelectedImages([...selectedImages, ...imageUrls]);
    //     }
    //   };
    //   reader.readAsDataURL(file);
    // }
  };
  const replaceImage = (error) => {
    console.log(error, "imageErrors");
    error.target.src = imagesForErrorShow;
  };
  return (
    <>
      <div className="card">
        <img
          src={value}
          alt={`Uploaded `}
          className="card-img-top"
          onError={replaceImage}
        />
        <div className="custom-file">
          <input
            type="file"
            ref={inputImgRef}
            className="custom-file-input"
            id="customFile"
            accept="image/gif, image/jpeg, image/png"
            onChange={handleImageChange}
          />

          <FontAwesomeIcon
            icon={faEdit}
            className="imageEditIcon"
            color="black"
          />
        </div>
      </div>
      {!!selectedImages && (
        <ImageCropper
          inputImgRef={inputImgRef}
          src={URL.createObjectURL(selectedImages)}
          changeProfilePhoto={changeImage}
          setImgFile={setSelectedImages}
        />
      )}
    </>
  );
};

export default ImageComponent;
