import { emailOtpVerification } from "../../../services/formValidator/authValidator";
import { verifyEmail } from "../../../services/api/authApis";
import { useFormik } from "formik";
import {
  Row,
  Col,
  Image,
  Form,
  Button,
  ListGroup,
  FormControl,
  InputGroup,
  Card,
} from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import auth1 from "../../../assets/images/auth/01.png";
import { useEffect } from "react";
import { toast } from "react-toastify";

const VerifyEmail = () => {
  const location = useLocation();
  //location.state.email
  const navigate = useNavigate();
  const verifyEmailFormik = useFormik({
    initialValues: emailOtpVerification.initialValues,
    validationSchema: emailOtpVerification.validationSchema,
    onSubmit: async (values) => {
      let data = await verifyEmail({
        otp: Number(values.otp),
        email: location.state?.email,
      });
      if (data.code === 200) {
        toast.success(data.message);
        navigate("/auth/reset-password", {
          state: { email: location.state?.email, otp: Number(values.otp) },
        });
      }
    },
  });
  useEffect(() => {
    if (!location.state?.email) {
      navigate("/");
    }
  }, []);
  const keyDownHandler = (e) => {
    var arrowsKeyCodes = [37, 38, 39, 40];
    var numPadNumberKeyCodes = [96, 97, 98, 99, 100, 101, 102, 103, 104, 105];
    var dots = [110, 190];
    var tabBackDel = [8, 9, 46];
    var acv = [65, 67, 86];

    // allow only [0-9] number, numpad number, arrow,  BackSpace, Tab, Del
    // Ctrl + C, Ctrl + V, Ctrl + A
    if (
      ((e.keyCode < 48 && arrowsKeyCodes.indexOf(e.keyCode) === -1) ||
        (e.keyCode > 57 &&
          numPadNumberKeyCodes.indexOf(e.keyCode) === -1 &&
          dots.indexOf(e.keyCode) === -1)) &&
      tabBackDel.indexOf(e.keyCode) === -1 &&
      (e.ctrlKey === false ||
        (e.ctrlKey === true && acv.indexOf(e.keyCode) === -1))
    ) {
      e.preventDefault();
    }
  };
  return (
    <>
      <section className="login-content">
        <Row className="m-0 align-items-center bg-white vh-100">
          <Col
            md="6"
            className="d-md-block d-none bg-primary p-0 mt-n1 vh-100 overflow-hidden"
          >
            <Image
              src={auth1}
              className="img-fluid gradient-main animated-scaleX"
              alt="images"
            />
          </Col>
          <Col md="6" className="p-0">
            <Card className="card-transparent auth-card shadow-none d-flex justify-content-center mb-0">
              <Card.Body>
                {/* <Link to="/dashboard" className="navbar-brand d-flex align-items-center mb-3">
                      <svg width="30" className="text-primary" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                         <rect x="-0.757324" y="19.2427" width="28" height="4" rx="2" transform="rotate(-45 -0.757324 19.2427)" fill="currentColor" />
                         <rect x="7.72803" y="27.728" width="28" height="4" rx="2" transform="rotate(-45 7.72803 27.728)" fill="currentColor" />
                         <rect x="10.5366" y="16.3945" width="16" height="4" rx="2" transform="rotate(45 10.5366 16.3945)" fill="currentColor" />
                         <rect x="10.5562" y="-0.556152" width="28" height="4" rx="2" transform="rotate(45 10.5562 -0.556152)" fill="currentColor" />
                      </svg>
                      <h4 className="logo-title ms-3">BI Reports</h4>
                   </Link> */}
                <h2 className="mb-2">Verify Otp </h2>
                <p>
                  An otp has been sent to your email {location.state?.email}{" "}
                  <Link onClick={() => navigate(-1)}>want to change</Link>.
                </p>
                <Form onSubmit={verifyEmailFormik.handleSubmit}>
                  <Row>
                    <Col lg="12" className="col-lg-12">
                      <Form.Group className="floating-label">
                        <Form.Label className="form-label">OTP</Form.Label>
                        <Form.Control
                          className="form-control"
                          type="text"
                          placeholder=""
                          id="otp"
                          name="otp"
                          value={verifyEmailFormik?.values.otp}
                          onChange={verifyEmailFormik.handleChange}
                          onKeyDown={(e) => keyDownHandler(e)}
                          maxLength={6}
                        />
                        <p className="form-error-message">
                          {verifyEmailFormik.touched.otp &&
                            verifyEmailFormik.errors.otp}
                        </p>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Button type="submit" className="mt-3" variant="primary">
                    Verify Otp
                  </Button>
                </Form>
              </Card.Body>
            </Card>
            <div className="sign-bg sign-bg-right">
              <svg
                width="280"
                height="230"
                viewBox="0 0 431 398"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g opacity="0.05">
                  <rect
                    x="-157.085"
                    y="193.773"
                    width="543"
                    height="77.5714"
                    rx="38.7857"
                    transform="rotate(-45 -157.085 193.773)"
                    fill="#3B8AFF"
                  />
                  <rect
                    x="7.46875"
                    y="358.327"
                    width="543"
                    height="77.5714"
                    rx="38.7857"
                    transform="rotate(-45 7.46875 358.327)"
                    fill="#3B8AFF"
                  />
                  <rect
                    x="61.9355"
                    y="138.545"
                    width="310.286"
                    height="77.5714"
                    rx="38.7857"
                    transform="rotate(45 61.9355 138.545)"
                    fill="#3B8AFF"
                  />
                  <rect
                    x="62.3154"
                    y="-190.173"
                    width="543"
                    height="77.5714"
                    rx="38.7857"
                    transform="rotate(45 62.3154 -190.173)"
                    fill="#3B8AFF"
                  />
                </g>
              </svg>
            </div>
          </Col>
        </Row>
      </section>
    </>
  );
};

export default VerifyEmail;
