import React, { useEffect, useState } from "react";
import CommonForm from "../commonForm";
import ImageSection from "../imageSection";
import { dynamicDataSectionVise } from "../../../../services/api/dynamicDataSectionVise";
import { Tab, Tabs } from "react-bootstrap";

const AboutBanner = () => {
  const [key, setKey] = useState();
  const [data, setData] = useState();
  const handleTabSelect = (selectedKey) => {
    setKey(selectedKey);
  };

  useEffect(() => {
    gettingDynamicData();
  }, []);
  const gettingDynamicData = async () => {
    let dataGet = await dynamicDataSectionVise(
      "cms_section_about_aboutUsBanner"
    );
    setData(dataGet);
  };
  return (
    <>
      <div className="tab-switch">
        <Tabs
          id="controlled-tab-example"
          activeKey={key}
          onSelect={handleTabSelect}
          className="mb-3"
        >
          <Tab eventKey="textSection" title="Text Section">
            <CommonForm
              sectionId={"cms_section_about_aboutUsBanner"}
              data={data}
            />
          </Tab>
          {data?.data?.pageContent?.image_fields && (
            <Tab eventKey="imageSection" title="Image Section">
              <ImageSection
                sectionId={"cms_section_about_aboutUsBanner"}
                data={data}
                gettingDynamicData={gettingDynamicData}
              />
            </Tab>
          )}
          {/* <Tab eventKey="contact" title="Contact" disabled>
        Tab content for Contact
      </Tab> */}
        </Tabs>
      </div>
    </>
  );
};

export default AboutBanner;
