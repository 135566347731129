import React from "react";
import { Route, Routes } from "react-router-dom";
import ServiceDetailsBanner from "./serviceDetailsBanner";
import ServiceDetailsCompanies from "./serviceDetailsCompanies";
import ServiceDetailsServices from "./serviceDetailsServices";

const ServiceDetails = () => {
  return (
    <Routes>
      <Route path="/banner" element={<ServiceDetailsBanner />} />
      <Route path="/companies" element={<ServiceDetailsCompanies />} />
      <Route path="/services" element={<ServiceDetailsServices />} />
    </Routes>
  );
};

export default ServiceDetails;
