import PoliciesCommonForm from "../policiesCommonForm";

const PrivacyAndPolicy = () => {
  return (
    <>
      <PoliciesCommonForm sectionId={"cms_section_privacy_policy"} />
    </>
  );
};

export default PrivacyAndPolicy;
