import { toast } from "react-toastify";
import http from "./http";

export const myProfileUpdate = async (values) => {
  let myprofileUpdate = await http.patch(
    `/admin/profile/update-profile-details`,
    values
  );
  if (myprofileUpdate?.code === 200) {
    toast.success(myprofileUpdate?.message);
    return myprofileUpdate;
  }
};

export const profileDataGet = async () => {
  let getProfileData = await http.get(`/admin/profile/get-profile-details`);
  if (getProfileData?.code === 200) {
    return getProfileData;
  }
};

export const updateProfileImag = async (bodyData) => {
  const data = await http.patch(
    "/admin/profile/update-profile-image",
    bodyData
  );
  if (data && data.code === 200) {
    toast.success(data.message);
    return data;
  }
};
export const getChangePassword = async (values) => {
  let changePasswordData = await http.patch(
    `/admin/profile/change-password`,
    values
  );
  if (changePasswordData?.code === 200) {
    toast.success(changePasswordData?.message);
    return changePasswordData;
  }
};
