import React, { useEffect, useState } from "react";
import CommonForm from "../commonForm";
import { dynamicDataSectionVise } from "../../../../services/api/dynamicDataSectionVise";

const ContactUs = () => {
  const [data, setData] = useState();

  useEffect(() => {
    gettingDynamicData();
  }, []);
  const gettingDynamicData = async () => {
    let dataGet = await dynamicDataSectionVise("cms_section_contact_details");
    setData(dataGet);
  };
  return (
    <>
      <CommonForm sectionId={"cms_section_contact_details"} data={data} />
    </>
  );
};

export default ContactUs;
