import CommonForm from "../commonForm";

const Testimonials = () => {
  return (
    <>
      <CommonForm sectionId={"cms_section_home_testimonials"} />
    </>
  );
};

export default Testimonials;
