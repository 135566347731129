import React from "react";
import { Button, Modal } from "react-bootstrap";

const ApprovedUser = ({
  showApprovedModal,
  setShowApprovedModal,
  message,
  approveHandler,
}) => {
  return (
    <Modal show={showApprovedModal} onHide={() => setShowApprovedModal(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Confirm Approved</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="alert alert-success">{message}</div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={() => setShowApprovedModal(false)}>
          Cancel
        </Button>
        <Button variant="success" onClick={approveHandler}>
          Approved
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ApprovedUser;
