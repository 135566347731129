import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  Image,
  InputGroup,
  Row,
} from "react-bootstrap";

import ImageComponent from "./imageComponent";

const ImageSection = ({ sectionId, data, gettingDynamicData }) => {
  const [obj, setObj] = useState({});

  useEffect(() => {
    setObj(data?.data?.pageContent?.image_fields);

    return () => setObj({});
  }, [data]);

  return (
    <div className="container mt-5">
      <div className="row justify-content-center">
        <div className="col">
          <div className="card">
            <div className="card-body">
              <div className="mt-4">
                {obj && (
                  <div className="row">
                    {Object.entries(obj).map(([innerKey, value]) => (
                      <>
                        <Form.Label className="form-label">
                          {innerKey}
                        </Form.Label>
                        <div className="col-md-4 mb-3">
                          <ImageComponent
                            image_field_key={innerKey}
                            obj={obj}
                            setObj={setObj}
                            value={value}
                            sectionId={sectionId}
                            gettingDynamicData={gettingDynamicData}
                          />
                        </div>
                      </>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImageSection;
