// import { createStore, combineReducers } from 'redux'
// import Mode from './setting/setting'
// export default createStore(
//     combineReducers({
//         mode: Mode
//     })
// )

import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistReducer } from "reduxjs-toolkit-persist";
import storage from "reduxjs-toolkit-persist/lib/storage";
import persistStore from "reduxjs-toolkit-persist/es/persistStore";
import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
} from "reduxjs-toolkit-persist/es/constants";
import { REDUX_PERSIST_KEY } from "../config";
import settingReducer from "./setting/reducers";
import authSlice from "./reducers/authSlice";
import loadingSlice from "./reducers/loadingSlice";
import userListSlice from "./reducers/userListSlice";
const persistConfig = {
  key: REDUX_PERSIST_KEY,
  storage,
  whitelist: ["auth"],
};
const combinedReducers = persistReducer(
  persistConfig,
  combineReducers({
    auth: authSlice,
    loading: loadingSlice,
    setting: settingReducer,
    users: userListSlice,
  })
);
const store = configureStore({
  reducer: combinedReducers,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});
const persistedStore = persistStore(store);
export { store, persistedStore };
