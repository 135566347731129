import React from "react";
import CommonForm from "../commonForm";

const ServiceDetailsServices = () => {
  return (
    <>
      <CommonForm sectionId={"cms_text_service_serviceDetailsServiceDetails"} />
    </>
  );
};

export default ServiceDetailsServices;
