import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userList: [],
};
export const userListSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    updateUserList: (state, action) => {
      let users = action.payload;
      state.userList = users;
    },
    clearUserList: (state, action) => {
      state.userList = [];
    },
  },
});
// this is for dispatch
export const { updateUserList, clearUserList } = userListSlice.actions;

// this is for configureStore
export default userListSlice.reducer;
