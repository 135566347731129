import React from "react";
import { Button, Modal } from "react-bootstrap";

const DeleteService = ({
  showDeleteServiceModal,
  setShowDeleteServiceModal,
  message,
  deleteHandler,
}) => {
  return (
    <Modal
      show={showDeleteServiceModal}
      onHide={() => setShowDeleteServiceModal(false)}
    >
      <Modal.Header closeButton>
        <Modal.Title>Confirm Delete</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="alert alert-success">{message}</div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="danger"
          onClick={() => setShowDeleteServiceModal(false)}
        >
          Cancel
        </Button>
        <Button variant="danger" onClick={deleteHandler}>
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteService;
