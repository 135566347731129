import React, { useEffect, useState } from "react";
import CommonForm from "../commonForm";
import { dynamicDataSectionVise } from "../../../../services/api/dynamicDataSectionVise";

const ServiceServices = () => {
  const [data, setData] = useState();

  useEffect(() => {
    gettingDynamicData();
  }, []);
  const gettingDynamicData = async () => {
    let dataGet = await dynamicDataSectionVise(
      "cms_section_service_serviceServices"
    );
    setData(dataGet);
  };
  return (
    <>
      <CommonForm
        sectionId={"cms_section_service_serviceServices"}
        data={data}
      />
    </>
  );
};

export default ServiceServices;
