import React from "react";
import { Route, Routes } from "react-router-dom";
import ContactBanner from "./contactBanner";
import ContactUs from "./contactUs";

const Contact = () => {
  return (
    <Routes>
      <Route path="/banner" element={<ContactBanner />} />
      <Route path="/contact_us" element={<ContactUs />} />
    </Routes>
  );
};

export default Contact;
