import { useEffect, useState } from "react";
import CommonForm from "../commonForm";
import { Tab, Tabs } from "react-bootstrap";
import ImageSection from "../imageSection";
import { dynamicDataSectionVise } from "../../../../services/api/dynamicDataSectionVise";

const HomeBanner = () => {
  const [key, setKey] = useState();
  const [data, setData] = useState();
  const handleTabSelect = (selectedKey) => {
    setKey(selectedKey);
  };

  useEffect(() => {
    gettingDynamicData();
  }, []);
  const gettingDynamicData = async () => {
    let dataGet = await dynamicDataSectionVise("cms_section_home_banner");
    setData(dataGet);
  };
  return (
    <div className="tab-switch">
      <Tabs
        id="controlled-tab-example"
        activeKey={key}
        onSelect={handleTabSelect}
        className="mb-3"
      >
        <Tab eventKey="textSection" title="Text Section">
          <CommonForm sectionId={"cms_section_home_banner"} data={data} />
        </Tab>
        {data?.data?.pageContent?.image_fields && (
          <Tab eventKey="imageSection" title="Image Section">
            <ImageSection
              sectionId={"cms_section_home_banner"}
              data={data}
              gettingDynamicData={gettingDynamicData}
            />
          </Tab>
        )}
        {/* <Tab eventKey="contact" title="Contact" disabled>
        Tab content for Contact
      </Tab> */}
      </Tabs>
    </div>
  );
};

export default HomeBanner;
