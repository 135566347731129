import * as Yup from "yup";

export const myProfileValidator = (user, type) => {
  if (type === "initialValues") {
    let initialValues = {
      first_name: user?.first_name ?? "",
      last_name: user?.last_name ?? "",
      about: user?.about ?? "",
    };
    return initialValues;
  }
  if (type === "validationSchema") {
    let validationSchema = Yup.object().shape({
      first_name: Yup.string()
        .transform((value) => value.trim())
        .required("First name field can not be empty")
        .matches(/^[a-zA-Z0-9\s]+$/, "Special characters are not allowed")
        .min(3, "Must be 3 characters")
        .max(20, "Max can be 30 characters"),
      last_name: Yup.string()
        .required("Last name field can not be empty")
        .matches(/^[a-zA-Z0-9\s]+$/, "Special characters are not allowed")
        .min(3, "Must be 3 characters")
        .max(20, "Max can be 30 characters"),
      about: Yup.string()
        .min(5, "Must be 3 characters")
        .max(150, "Max can be 150 characters"),
    });
    return validationSchema;
  }
};
