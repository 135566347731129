import React from "react";
import CommonForm from "../commonForm";

const ServiceDetailsBanner = () => {
  return (
    <>
      <CommonForm sectionId={"cms_section_service_serviceDetailsBanner"} />
    </>
  );
};

export default ServiceDetailsBanner;
