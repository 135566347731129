import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  noOfApiCall: [],
};
export const loadingSlice = createSlice({
  name: "loading",
  initialState,
  reducers: {
    startLoading: (state, action) => {
      let apiCalls = state.noOfApiCall;
      apiCalls.push(action.payload);
      state.loading = true;
      state.noOfApiCall = apiCalls;
    },
    stopLoading: (state, action) => {
      let apiCalls = state.noOfApiCall;
      apiCalls = apiCalls.filter((item) => item !== action.payload);
      state.loading = false;
      state.noOfApiCall = apiCalls;
    },
  },
});
// this is for dispatch
export const { startLoading, stopLoading } = loadingSlice.actions;

// this is for configureStore
export default loadingSlice.reducer;
