//router
// import IndexRouters from "./router/index"

//scss
import "./assets/scss/hope-ui.scss";
import "./assets/scss/custom.scss";
import "./assets/scss/dark.scss";
import "./assets/scss/rtl.scss";
import "./assets/scss/customizer.scss";

// Redux Selector / Action
import { useDispatch, useSelector } from "react-redux";

// import state selectors
import { setSetting } from "./store/setting/actions";
import RouteIdentifier from "./router/RouteIdentifier";
import routes from "./router/router";
import Loader from "./components/Loader";

function App({ children }) {
  const { loading } = useSelector((state) => state.loading);
  // const dispatch = useDispatch()
  // dispatch(setSetting())
  return (
    <>
      {/* <IndexRouters /> */}
      {loading && <Loader />}

      <RouteIdentifier routes={routes} />
    </>
  );
}

export default App;
