import {
  Row,
  Col,
  Image,
  Form,
  Button,
  ListGroup,
  FormControl,
  InputGroup,
  Card,
} from "react-bootstrap";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  startLoading,
  stopLoading,
} from "../../../../store/reducers/loadingSlice";
import {
  createNewService,
  dynamicDataSectionVise,
  dynamicDataUpdateSectionVise,
} from "../../../../services/api/dynamicDataSectionVise";
import { useLocation } from "react-router-dom";
import ImageSection from "../imageSection";
const CreateServices = () => {
  const [obj, setObj] = useState({});
  let location = useLocation();
  const [title, setTitle] = useState("");
  const [subTitle, setSubTitle] = useState("");
  const [details, setDetails] = useState("");
  const [imageFieldKey, setImageFieldKey] = useState("");
  const [imageSection, setImageSection] = useState(false);
  const [dataGetImage, setDataGetImage] = useState();
  const [sectionId, setSectionId] = useState("");
  const dispatch = useDispatch();
  useEffect(() => {
    if (location?.state?.type === "edit") {
      (async () => {
        let data = await dynamicDataSectionVise(location?.state?.section_id);
        setObj(data?.data?.pageContent?.text_fields);
      })();
    }

    return () => setObj({});
  }, []);
  useEffect(() => {
    if (imageSection) {
      gettingDynamicData();
    }
  }, [imageSection]);
  const gettingDynamicData = async () => {
    let dataGet = await dynamicDataSectionVise(sectionId);
    setDataGetImage(dataGet);
  };
  const submitHandler = async (e) => {
    let titleKey = `cms_text_service_title_${Date.now()}`;
    let subTitleKey = `cms_text_service_link_${Date.now()}`;
    let detailsKey = `cms_text_service_details_${Date.now()}`;
    let imageKey = `cms_image_service_thumbnail_${Date.now()}`;
    setImageFieldKey(imageKey);
    const data = {
      section_id: sectionId,
      text_fields: {
        en: {
          [titleKey]: title,
          [subTitleKey]: subTitle,
          [detailsKey]: details,
        },
      },
      image_fields: {
        [imageKey]: "",
      },
    };
    dispatch(startLoading());
    let serviceCreateData = await createNewService(data);
    if (serviceCreateData?.code === 201) {
      setImageSection(true);
      dispatch(stopLoading());
    }
  };
  const onChangeHandlerDetails = (event, editor) => {
    const data = editor.getData();
    setDetails(data);
  };
  const onChangeHandlerTitle = (e) => {
    setSectionId(
      `cms_section_service_${e.target.value
        .trim()
        .toLowerCase()
        .slice(0, 5)}_${Date.now()}`
    );

    setTitle(e.target.value);
  };
  const onChangeHandlerSubTitle = (e) => {
    setSubTitle(e.target.value);
  };
  const submitHandlerEdit = async (e) => {
    // e.preventDefault();
    dispatch(startLoading());
    let data = await dynamicDataUpdateSectionVise(
      location?.state?.section_id,
      obj
    );
    if (data?.code === 200) {
      dispatch(stopLoading());
    }
  };
  const onChangeHandlerEdit = (e, outerKey, innerKey) => {
    const newValue = e.target.value;
    setObj((prevState) => ({
      ...prevState,
      [outerKey]: {
        ...prevState[outerKey],
        [innerKey]: newValue,
      },
    }));
  };
  const onChangeHandlerEditCk = (event, editor, outerKey, innerKey) => {
    const data = editor.getData();
    setObj((prevState) => ({
      ...prevState,
      [outerKey]: {
        ...prevState[outerKey],
        [innerKey]: data,
      },
    }));
  };

  return (
    <>
      {!imageSection ? (
        <section className="login-content">
          <Row className="m-0 align-items-center bg-white vh-100">
            <Col md="12" className="p-0">
              <Card className="card-transparent auth-card shadow-none d-flex justify-content-center mb-0">
                <Card.Body>
                  {location?.state?.type === "edit" && (
                    <Form onSubmit={(e) => e.preventDefault()}>
                      <Row>
                        {obj &&
                          Object.keys(obj).map((outerKey) => (
                            <>
                              {obj &&
                                Object.entries(obj[outerKey]).map(
                                  ([innerKey, value]) => (
                                    <>
                                      <Form.Label className="form-label">
                                        {innerKey}
                                      </Form.Label>
                                      {!innerKey.includes(
                                        "cms_text_service_details"
                                      ) && (
                                        <InputGroup>
                                          <Form.Control
                                            className="form-control"
                                            as="textarea"
                                            placeholder=""
                                            id={innerKey}
                                            name={innerKey}
                                            value={value}
                                            onChange={(e) =>
                                              onChangeHandlerEdit(
                                                e,
                                                outerKey,
                                                innerKey
                                              )
                                            }
                                          />
                                        </InputGroup>
                                      )}

                                      {innerKey.includes(
                                        "cms_text_service_details"
                                      ) && (
                                        <CKEditor
                                          editor={Editor}
                                          data={value}
                                          onChange={(event, editor) =>
                                            onChangeHandlerEditCk(
                                              event,
                                              editor,
                                              outerKey,
                                              innerKey
                                            )
                                          }
                                        />
                                      )}
                                    </>
                                  )
                                )}
                            </>
                          ))}
                      </Row>
                      <Button
                        onClick={submitHandlerEdit}
                        type="button"
                        className="btn-scroll"
                        variant="primary"
                      >
                        Update Details
                      </Button>
                    </Form>
                  )}
                  {location?.state?.type === "add" && (
                    <Form onSubmit={(e) => e.preventDefault()}>
                      <Row>
                        <Form.Label className="form-label">Title</Form.Label>
                        <InputGroup>
                          <Form.Control
                            className="form-control"
                            as="textarea"
                            value={title}
                            placeholder="Title"
                            name="title"
                            onChange={(e) => onChangeHandlerTitle(e)}
                          />
                        </InputGroup>
                        <Form.Label className="form-label">Subtitle</Form.Label>
                        <InputGroup>
                          <Form.Control
                            className="form-control"
                            as="textarea"
                            placeholder="Subtitle"
                            name="subTitle"
                            value={subTitle}
                            onChange={(e) => onChangeHandlerSubTitle(e)}
                          />
                        </InputGroup>
                        <Form.Label className="form-label">Details</Form.Label>
                        <div className="service-ck">
                          <CKEditor
                            editor={Editor}
                            data={details}
                            onChange={(event, editor) =>
                              onChangeHandlerDetails(event, editor)
                            }
                          />
                        </div>
                      </Row>
                      <Button
                        onClick={submitHandler}
                        type="button"
                        className="btn-scroll"
                        variant="primary"
                      >
                        Add New Service
                      </Button>
                    </Form>
                  )}
                </Card.Body>
              </Card>
              <div className="sign-bg sign-bg-right">
                <svg
                  width="280"
                  height="230"
                  viewBox="0 0 431 398"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g opacity="0.05">
                    <rect
                      x="-157.085"
                      y="193.773"
                      width="543"
                      height="77.5714"
                      rx="38.7857"
                      transform="rotate(-45 -157.085 193.773)"
                      fill="#3B8AFF"
                    />
                    <rect
                      x="7.46875"
                      y="358.327"
                      width="543"
                      height="77.5714"
                      rx="38.7857"
                      transform="rotate(-45 7.46875 358.327)"
                      fill="#3B8AFF"
                    />
                    <rect
                      x="61.9355"
                      y="138.545"
                      width="310.286"
                      height="77.5714"
                      rx="38.7857"
                      transform="rotate(45 61.9355 138.545)"
                      fill="#3B8AFF"
                    />
                    <rect
                      x="62.3154"
                      y="-190.173"
                      width="543"
                      height="77.5714"
                      rx="38.7857"
                      transform="rotate(45 62.3154 -190.173)"
                      fill="#3B8AFF"
                    />
                  </g>
                </svg>
              </div>
            </Col>
          </Row>
        </section>
      ) : (
        <ImageSection
          sectionId={sectionId}
          data={dataGetImage}
          gettingDynamicData={gettingDynamicData}
        />
      )}
    </>
  );
};

export default CreateServices;
