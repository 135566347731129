import React, { useEffect, useState } from "react";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  dynamicDataSectionVise,
  dynamicDataUpdateSectionVise,
} from "../../../services/api/dynamicDataSectionVise";
import {
  startLoading,
  stopLoading,
} from "../../../store/reducers/loadingSlice";
import { Button } from "react-bootstrap";
const PoliciesCommonForm = ({ sectionId }) => {
  const dispatch = useDispatch();
  const [privacyData, setPrivacyData] = useState("<p></p>");
  const [obj, setObj] = useState({});
  const editorConfiguration = {
    toolbar: ["bold", "italic"],
  };
  useEffect(() => {
    (async () => {
      let data = await dynamicDataSectionVise(sectionId);
      setObj(data?.data?.pageContent?.text_fields);
    })();
    return () => setObj({});
  }, []);
  const updateHandler = async (e) => {
    e.preventDefault();

    dispatch(startLoading());
    let data = await dynamicDataUpdateSectionVise(sectionId, obj);
    if (data?.code === 200) {
      dispatch(stopLoading());
    }
    // let updatePrivacyData=await dynamicDataUpdateSectionVise("cms_section_privacy_policy")
  };
  const onChangeHandler = (event, editor, outerKey, innerKey) => {
    const data = editor.getData();
    setObj((prevState) => ({
      ...prevState,
      [outerKey]: {
        ...prevState[outerKey],
        [innerKey]: data,
      },
    }));
  };
  return (
    <>
      {Object.keys(obj).map((outerKey) => (
        <>
          {Object.entries(obj[outerKey]).map(([innerKey, value]) => (
            <>
              <CKEditor
                editor={Editor}
                // config={ editorConfiguration }
                data={value}
                onChange={(event, editor) =>
                  onChangeHandler(event, editor, outerKey, innerKey)
                }
              />
              <div>
                <Button
                  onClick={(e) => updateHandler(e)}
                  type="button"
                  className="btn-scroll"
                  variant="primary"
                >
                  Update Details
                </Button>
              </div>
            </>
          ))}
        </>
      ))}
    </>
  );
};

export default PoliciesCommonForm;
