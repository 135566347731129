import React from "react";
import ServiceBanner from "./serviceBanner";
import { Route, Routes } from "react-router-dom";
import ServiceCompanies from "./serviceCompanies";
import ServiceServices from "./serviceServices";
import AllServices from "./allServices";
import CreateServices from "./createServices";

const Services = () => {
  return (
    <Routes>
      <Route path="/banner" element={<ServiceBanner />} />
      <Route path="/companies" element={<ServiceCompanies />} />
      <Route path="/services" element={<ServiceServices />} />
      <Route path="/all_services" element={<AllServices />} />
      <Route path="/all_services/create_service" element={<CreateServices />} />
    </Routes>
  );
};

export default Services;
