import React, { useEffect } from 'react'
import Default from '../../../layouts/dashboard/default'
import Sidebar from '../../partials/dashboard/SidebarStyle/sidebar'
import { useDispatch, useSelector } from 'react-redux';
// import UserHeader from '../components/common/Headers/UserHeader'
// import UserFooter from '../components/common/Footers/UserFooter'
// import { Route, Routes } from 'react-router-dom'
import * as SettingSelector from "../../../store/setting/selectors";
import Header from '../../partials/dashboard/HeaderStyle/header';
import SubHeader from '../../partials/dashboard/HeaderStyle/sub-header';
import Footer from '../../partials/dashboard/FooterStyle/footer';
import { userLogout } from '../../../services/api/authApis';
import { useNavigate } from 'react-router-dom';

const UserLayout = ({ children }) => {
    const appName = useSelector(SettingSelector.app_name);
    const navigate = useNavigate()
    const dispatch = useDispatch()
    useEffect(() => {
        if (!sessionStorage.getItem("_rt")) {
            logOutHandler()
        }
    }, [])
    const logOutHandler = () => {
        userLogout(dispatch);
        navigate("/");
    };

    return (<>

        <Sidebar app_name={appName} />
        <main className="main-content">
            <div className="position-relative">
                <Header />
                <SubHeader />

            </div>
            <div className="py-0 conatiner-fluid content-inner mt-n5">
                {/* <DefaultRouter /> */}
                {children}
            </div>


            <Footer />

        </main>

        {/* <UserFooter /> */}
    </>
    )
}

export default UserLayout