import { toast } from "react-toastify";
import http from "./http";

export const dynamicDataSectionVise = async (val) => {
  let getDynamicData = await http.get(
    `/admin/page-content/get-page-content/${val}`
  );
  if (getDynamicData?.code === 200) {
    return getDynamicData;
  }
};
export const dynamicDataUpdateSectionVise = async (sectionId, obj) => {
  let values = { text_fields: obj };
  let updateDynamicData = await http.patch(
    `/admin/page-content/update-page-content/${sectionId}`,
    values
  );
  if (updateDynamicData?.code === 200) {
    toast.success(updateDynamicData?.message);
    return updateDynamicData;
  }
};
export const dynamicDataUpdateSectionViseImage = async (
  sectionId,
  imageKey,
  selectedImages
) => {
  let updateDynamicData = await http.patch(
    `/admin/page-content/update-page-content-image/${sectionId}/${imageKey}`,
    selectedImages
  );
  if (updateDynamicData?.code === 200) {
    toast.success(updateDynamicData?.message);
    return updateDynamicData;
  }
};

////////////////////craeting services
export const createNewService = async (obj) => {
  let values = obj;
  let addServices = await http.post(`/admin/page-content/add-service`, values);
  if (addServices?.code === 201) {
    toast.success(addServices?.message);
    return addServices;
  }
};
export const getAllService = async (val) => {
  let getServices = await http.get(`/admin/page-content/get-services/${val}`);
  if (getServices?.code === 200) {
    return getServices;
  }
};
export const deleteService = async (serviceId, sectionId) => {
  const val = { service_id: serviceId, section_id: sectionId };
  let deleteData = await http.delete(
    `/admin/page-content/delete-service/${serviceId}/${sectionId}`,
    val
  );
  if (deleteData?.code === 200) {
    return deleteData;
  }
};
