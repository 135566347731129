import * as Yup from "yup";

/**
 * Login form validator for login
 */
export const loginFromValidator = {
  initialValues: {
    email: "",
    password: "",
  },
  validationSchema: Yup.object().shape({
    email: Yup.string()
      .email("Invalid email address")

      .required("Email field cannot be empty"),
    password: Yup.string()
      .min(8, "Must be 8 or more than 8 characters")
      .required("Password field is Required")
      .matches(/\w/, "Please enter valid password"),
  }),
};

/**
 * Forgot password form validator
 */
export const forgotPassFromValidator = {
  initialValues: {
    email: "",
  },
  validationSchema: Yup.object().shape({
    email: Yup.string()
      .email("Invalid email address")
      .required("Email field cannot be empty"),
  }),
};
/**
 *Otp form validator
 */
export const otpFromValidator = {
  initialValues: {
    otp: "",
  },
  validationSchema: Yup.object().shape({
    otp: Yup.string()
      .required("Otp field is Required")
      .matches(/^[0-9]/, "Please enter valid otp"),
  }),
};
/**
 *Reset Password form validator
 */
export const resetPassFormValidator = {
  initialValues: {
    newPassword: "",
    confrmPassword: "",
  },
  validationSchema: Yup.object().shape({
    newPassword: Yup.string()
      .min(8, "Must be 8 or more than 8 characters")
      .required("Password field is Required")
      .matches(
        /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        "Password must be eight characters including atleast one uppercase letter, one lowercase letter, one digit and one special character!"
      )
      .matches(/^(?!.*\s)/, "Spaces are not allowed"),
    confrmPassword: Yup.string()
      .oneOf([Yup.ref("newPassword"), ""], "Password must match")
      .required("Confirm password field is required")
      .matches(
        /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        "Password must be eight characters including atleast one uppercase letter, one lowercase letter, one digit and one special character!"
      )
      .matches(/^(?!.*\s)/, "Spaces are not allowed"),
  }),
};

/**
 * Email otp verification
 */
export const emailOtpVerification = {
  initialValues: {
    otp: "",
  },
  validationSchema: Yup.object().shape({
    otp: Yup.string()
      .min(6, "Must be 6 characters")
      .required("OTP field is Required")
      .matches(/^[0-9]/, "Please enter valid otp"),
  }),
};

// social login email add to non mail account form
export const addEmailVerification = {
  initialValues: {
    otp: "",
  },
  validationSchema: Yup.object().shape({
    otp: Yup.string()
      .min(6, "Must be 6 characters")
      .required("OTP field is Required")
      .matches(/^[0-9]/, "Please enter valid otp"),
  }),
};
// change password
export const changePassFormValidator = {
  initialValues: {
    oldPassword: "",
    newPassword: "",
    confrmPassword: "",
  },
  validationSchema: Yup.object().shape({
    oldPassword: Yup.string()
      .min(8, "Must be 8 or more than 8 characters")
      .required("Password field is Required")
      .matches(
        /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        "Password must be eight characters including atleast one uppercase letter, one lowercase letter, one digit and one special character!"
      )
      .matches(/^(?!.*\s)/, "Spaces are not allowed"),
    newPassword: Yup.string()
      .min(8, "Must be 8 or more than 8 characters")
      .required("Password field is Required")
      .matches(
        /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        "Password must be eight characters including atleast one uppercase letter, one lowercase letter, one digit and one special character!"
      )
      .matches(/^(?!.*\s)/, "Spaces are not allowed"),
    confrmPassword: Yup.string()
      .oneOf([Yup.ref("newPassword"), ""], "Password must match")
      .required("Password field is required")
      .matches(
        /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        "Password must be eight characters including atleast one uppercase letter, one lowercase letter, one digit and one special character!"
      )
      .matches(/^(?!.*\s)/, "Spaces are not allowed"),
  }),
};
