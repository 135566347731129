import React from "react";
import { Route, Routes } from "react-router-dom";
import AboutBanner from "./aboutBanners";
import AboutAboutUs from "./aboutAboutUs";
import AboutProducts from "./aboutProducts";

const About = () => {
  return (
    <Routes>
      <Route path="/banner" element={<AboutBanner />} />
      <Route path="/about" element={<AboutAboutUs />} />
      <Route path="/products" element={<AboutProducts />} />
    </Routes>
  );
};

export default About;
