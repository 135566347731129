import { useEffect, memo, Fragment, useContext, useState } from "react";
import { useLocation, Outlet, Link, useNavigate } from "react-router-dom";

//react-shepherd
import { ShepherdTourContext } from "react-shepherd";

//react-bootstrap
import { Button, Card, Col, Form, Image, Row } from "react-bootstrap";

// header
import Header from "../../components/partials/dashboard/HeaderStyle/header";

//subheader
import SubHeader from "../../components/partials/dashboard/HeaderStyle/sub-header";

//sidebar
import Sidebar from "../../components/partials/dashboard/SidebarStyle/sidebar";

//footer
import Footer from "../../components/partials/dashboard/FooterStyle/footer";

//default
// import {DefaultRouter} from '../../router/default-router'

//seetingoffCanvas
import SettingOffCanvas from "../../components/setting/SettingOffCanvas";

import Loader from "../../components/Loader";

// Import selectors & action from setting store
import * as SettingSelector from "../../store/setting/selectors";

// Redux Selector / Action
import { useDispatch, useSelector } from "react-redux";
import { startLoading, stopLoading } from "../../store/reducers/loadingSlice";
import {
  approvedUserList,
  getUserDetails,
  getUserList,
} from "../../services/api/usersApi";
import {
  clearUserList,
  updateUserList,
} from "../../store/reducers/userListSlice";
import { toast } from "react-toastify";
import ApprovedUser from "../../components/common/approvedUser/approvedUser";
import { debounce } from "lodash";
import { userLogout } from "../../services/api/authApis";

const Tour = () => {
  const tour = useContext(ShepherdTourContext);
  const { pathname } = useLocation();
  useEffect(() => {
    if (
      pathname === "/dashboard" &&
      sessionStorage.getItem("tour") !== "true"
    ) {
      tour?.start();
    }
  });
  return <Fragment></Fragment>;
};

const Default = memo((props) => {
  // let location = useLocation();
  // const pageLayout = useSelector(SettingSelector.page_layout);
  const dispatch = useDispatch();
  const appName = useSelector(SettingSelector.app_name);
  // useEffect(() => {});
  useEffect(() => {
    dispatch(startLoading());
    setTimeout(() => {
      dispatch(stopLoading());
    }, 1000);
  }, []);

  const { userList } = useSelector((state) => state.users);
  // const [userGetPage, setUserGetPage] = useState([]);
  const [current_page, setCurrentPage] = useState(0);
  const [userGetTotalPage, setUserGetTotalPage] = useState([]);
  const [userSearch, setUserSearch] = useState("");
  const [showApprovedModal, setShowApprovedModal] = useState(false);
  const [userId, setUserId] = useState("");
  const [userFirstName, setUserFirstName] = useState("");
  const [userLastName, setUserLastName] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      let userList = await getUserList({
        current_page: 1,
        status: "not_approved",
        limit: 10,
      });
      if (userList) {
        dispatch(updateUserList(userList?.data?.users));
        // setUserGetPage(userList?.data?.page);
        setCurrentPage(userList?.data?.page);
        // setUserGetPage(Array(userList?.data?.page).fill("page"));
        setUserGetTotalPage(
          Array(userList?.data?.totalPages).fill("totalPages")
        );
      }
    })();
    return () => {
      // setUserGetPage(0);
      dispatch(clearUserList());
      setUserSearch("");
    };
  }, []);
  // useEffect(()=>{
  //   setUserGetPage(Array(Math.ceil(userList.length / 10)).fill("page"));
  // },[userList])
  useEffect(() => {
    dispatch(startLoading());
    setTimeout(() => {
      dispatch(stopLoading());
    }, 1000);
  }, []);
  const loadMoreData = async (page) => {
    let userList = await getUserList({
      current_page: page,
      limit: 10,
      status: "not_approved",
      search_value: userSearch,
    });
    setCurrentPage(userList?.data?.page);
    dispatch(updateUserList(userList?.data?.users));
    // setUserGetPage(Array(userList?.data?.page).fill("page"));
  };
  const approveHandler = async () => {
    const approvedList = await approvedUserList(userId);
    if (approvedList) {
      dispatch(startLoading());
      let newUserList = userList.filter((item) => {
        if (item.id !== userId) {
          return item;
        }
      });
      dispatch(updateUserList(newUserList));
      setShowApprovedModal(false);
      setUserId("");
      setUserFirstName("");
      setUserLastName("");
      toast.success(approvedList?.message);
      dispatch(stopLoading());
    }
  };
  const userDetails = async (id) => {
    const userDetailsData = await getUserDetails(id);
  };

  const searchHandler = debounce(async (e) => {
    setUserSearch(e.target.value.trim());
    if (e.target.value !== "") {
      let userListData = await getUserList({
        current_page: 1,
        limit: 10,
        status: "not_approved",
        search_value: e.target.value.trim(),
      });
      // setUserGetPage(userListData?.data?.page);
      setCurrentPage(userListData?.data?.page);
      setUserGetTotalPage(
        Array(userListData?.data?.totalPages).fill("totalPages")
      );
      dispatch(updateUserList(userListData?.data?.users));
    } else {
      let userPreviousList = await getUserList({
        current_page: current_page,
        limit: 10,
        status: "not_approved",
      });
      // setUserGetPage(userListData?.data?.page);
      setCurrentPage(userPreviousList?.data?.page);
      setUserGetTotalPage(
        Array(userPreviousList?.data?.totalPages).fill("totalPages")
      );
      dispatch(updateUserList(userPreviousList?.data?.users));
    }
  }, 500);
  const openApprovedModel = (id, firstName, lastName) => {
    setUserId(id);
    setUserFirstName(firstName);
    setUserLastName(lastName);
    setShowApprovedModal(true);
  };

  // const closeTour = () => {
  //   sessionStorage.setItem("tour", "true");
  // };

  // shepherd
  // const newSteps = [
  //   {
  //     title: "<h4>Menu</h4>",
  //     text: '<p className="mb-0">Check the content under Menu Style. Click to view all oavailable Menu Style options for you.</p>',
  //     attachTo: { element: ".sidebar ", on: "right" },
  //     buttons: [
  //       {
  //         type: "next",
  //         text: "Next",
  //       },
  //     ],
  //     when: {
  //       show: () => {
  //         document
  //           .querySelector(".shepherd-modal-overlay-container")
  //           .classList.add("shepherd-modal-is-visible");
  //       },
  //       cancel: () => closeTour(),
  //     },
  //   },
  //   {
  //     title: "<h4>Profile Setting</h4>",
  //     text: '<p className="mb-0">Configure your Profile using Profile Settings. Edit, save and update your profile from here.</p>',
  //     attachTo: { element: ".iq-tour ", on: "bottom" },
  //     buttons: [
  //       {
  //         type: "back",
  //         classes: "shepherd-button-secondary",
  //         text: "Back",
  //       },
  //       {
  //         type: "next",
  //         text: "Next",
  //       },
  //     ],
  //     when: {
  //       cancel: () => closeTour(),
  //     },
  //   },
  //   {
  //     title: "<h4>Live Customizer</h4>",
  //     text: '<p className="mb-0">Transform the entire look, color, style and appearance of using Live Customizer settings. Change and copy the settings from here.</p>',
  //     attachTo: { element: ".btn-setting", on: "left" },
  //     buttons: [
  //       {
  //         type: "back",
  //         classes: "shepherd-button-secondary",
  //         text: "Back",
  //       },
  //       {
  //         action() {
  //           sessionStorage.setItem("tour", "true");
  //           return this.next();
  //         },
  //         text: "Done",
  //       },
  //     ],
  //     when: {
  //       cancel: () => closeTour(),
  //     },
  //   },
  // ];
  // const tourOptions = {
  //   defaultStepOptions: {
  //     cancelIcon: {
  //       enabled: true,
  //     },
  //   },
  //   when: {
  //     cancel: function () {},
  //   },
  // };
  // var subHeader = "";
  // var commanclass = "";
  // switch (location.pathname) {
  //   case '/dashboard':
  //   case "/dashboard/special-pages/calender":
  //   case "/dashboard/special-pages/billing":
  //   case "/dashboard/special-pages/kanban":
  //   case "/dashboard/special-pages/pricing":
  //   case "/dashboard/special-pages/timeline":
  //   case "/dashboard/table/table-data":
  //   case "/dashboard/table/bootstrap-table":
  //   case "/dashboard/table/border-table":
  //   case "/dashboard/table/fancy-table":
  //   case "/dashboard/table/fixed-table":
  //   case "/dashboard/icon/solid":
  //   case "/dashboard/icon/outline":
  //   case "/dashboard/icon/dual-tone":
  //     subHeader = <SubHeader />;
  //     commanclass = "iq-banner default";
  //     break;
  //   default:
  //     break;
  // }

  return (
    <Fragment>
      {/* <Loader /> */}

      <div>
        <Row>
          <Col sm="12">
            <Card>
              <Card.Header className="d-flex justify-content-between">
                <div className="header-title">
                  <h4 className="card-title">User List</h4>
                </div>

                <div classname="col-md-6">
                  <div
                    id="user-list-table_filter"
                    classname="dataTables_filter"
                  >
                    <Form>
                      <Row>
                        <Col lg="12">
                          <Form.Group className="form-group">
                            <Form.Control
                              type="search"
                              classname="form-control form-control-sm"
                              placeholder="Search by email or name..."
                              aria-describedby="email"
                              aria-controls="user-list-table"
                              onChange={(e) => searchHandler(e)}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </div>
              </Card.Header>
              <Card.Body className="px-0">
                <div className="table-responsive">
                  <table
                    id="user-list-table"
                    className="table table-striped"
                    role="grid"
                    data-toggle="data-table"
                  >
                    <thead>
                      <tr className="ligth">
                        <th>Profile</th>
                        <th>Name</th>
                        {/* <th>Contact</th> */}
                        <th>Email</th>
                        {/* <th>Country</th> */}
                        <th>Status</th>
                        {/* <th>Company</th> */}
                        <th>Join Date</th>
                        <th min-width="100px">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {userList &&
                        userList?.length > 0 &&
                        userList.map(
                          (item, idx) =>
                            item?.status !== "active" && (
                              <tr key={idx}>
                                <td className="text-center">
                                  <Image
                                    style={{ cursor: "pointer" }}
                                    className="bg-soft-primary rounded img-fluid avatar-40 me-3"
                                    src={
                                      item?.profile_image ||
                                      "/default-profile-pic.jpg"
                                    }
                                    alt="profile"
                                  />
                                </td>
                                <td onClick={() => userDetails(item?.id)}>
                                  {item?.first_name} {item?.last_name}
                                </td>
                                {/* <td>{item.phone}</td> */}
                                <td>{item?.email}</td>
                                {/* <td>{item.country}</td> */}
                                <td>
                                  <span
                                    className={`badge ${
                                      (item?.status === "active" &&
                                        "bg-primary") ||
                                      (item?.status === "not_approved" &&
                                        "bg-warning") ||
                                      (item?.status === "Inactive" &&
                                        "bg-danger")
                                    }`}
                                  >
                                    {(item?.status === "active" &&
                                      "Approved") ||
                                      (item?.status === "not_approved" &&
                                        "Not Approved")}
                                  </span>
                                </td>
                                {/* <td>{item.company}</td> */}
                                <td>
                                  {new Date(item?.createdAt).toLocaleDateString(
                                    "en-US",
                                    {
                                      day: "numeric",
                                      month: "long",
                                      year: "numeric",
                                    }
                                  )}
                                </td>
                                <td>
                                  <div className="flex align-items-center list-user-action">
                                    {item?.status !== "active" && (
                                      <Link
                                        className="btn btn-sm btn-icon btn-success"
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title="Approve"
                                        data-original-title="Approve"
                                        to="#"
                                        onClick={() =>
                                          openApprovedModel(
                                            item?.id,
                                            item?.first_name,
                                            item?.last_name
                                          )
                                        }
                                      >
                                        <span className="btn-inner">
                                          <svg
                                            width="32"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              fillRule="evenodd"
                                              clipRule="evenodd"
                                              d="M9.87651 15.2063C6.03251 15.2063 2.74951 15.7873 2.74951 18.1153C2.74951 20.4433 6.01251 21.0453 9.87651 21.0453C13.7215 21.0453 17.0035 20.4633 17.0035 18.1363C17.0035 15.8093 13.7415 15.2063 9.87651 15.2063Z"
                                              stroke="currentColor"
                                              strokeWidth="1.5"
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                            ></path>
                                            <path
                                              fillRule="evenodd"
                                              clipRule="evenodd"
                                              d="M9.8766 11.886C12.3996 11.886 14.4446 9.841 14.4446 7.318C14.4446 4.795 12.3996 2.75 9.8766 2.75C7.3546 2.75 5.3096 4.795 5.3096 7.318C5.3006 9.832 7.3306 11.877 9.8456 11.886H9.8766Z"
                                              stroke="currentColor"
                                              strokeWidth="1.5"
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                            ></path>
                                            <path
                                              d="M19.2036 8.66919V12.6792"
                                              stroke="currentColor"
                                              strokeWidth="1.5"
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                            ></path>
                                            <path
                                              d="M21.2497 10.6741H17.1597"
                                              stroke="currentColor"
                                              strokeWidth="1.5"
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                            ></path>
                                          </svg>
                                        </span>
                                      </Link>
                                    )}{" "}
                                    {/* <Link className="btn btn-sm btn-icon btn-warning" data-toggle="tooltip" data-placement="top" title="Edit" data-original-title="Edit" to="#">
                                          <span className="btn-inner">
                                             <svg width="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M11.4925 2.78906H7.75349C4.67849 2.78906 2.75049 4.96606 2.75049 8.04806V16.3621C2.75049 19.4441 4.66949 21.6211 7.75349 21.6211H16.5775C19.6625 21.6211 21.5815 19.4441 21.5815 16.3621V12.3341" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                <path fillRule="evenodd" clipRule="evenodd" d="M8.82812 10.921L16.3011 3.44799C17.2321 2.51799 18.7411 2.51799 19.6721 3.44799L20.8891 4.66499C21.8201 5.59599 21.8201 7.10599 20.8891 8.03599L13.3801 15.545C12.9731 15.952 12.4211 16.181 11.8451 16.181H8.09912L8.19312 12.401C8.20712 11.845 8.43412 11.315 8.82812 10.921Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                <path d="M15.1655 4.60254L19.7315 9.16854" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                             </svg>
                                          </span>
                                       </Link>{' '} */}
                                    {/* <Link
                                  className="btn btn-sm btn-icon btn-danger"
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title="Delete"
                                  data-original-title="Delete"
                                  to="#"
                                >
                                  <span className="btn-inner">
                                    <svg
                                      width="20"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                      stroke="currentColor"
                                    >
                                      <path
                                        d="M19.3248 9.46826C19.3248 9.46826 18.7818 16.2033 18.4668 19.0403C18.3168 20.3953 17.4798 21.1893 16.1088 21.2143C13.4998 21.2613 10.8878 21.2643 8.27979 21.2093C6.96079 21.1823 6.13779 20.3783 5.99079 19.0473C5.67379 16.1853 5.13379 9.46826 5.13379 9.46826"
                                        stroke="currentColor"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      ></path>
                                      <path
                                        d="M20.708 6.23975H3.75"
                                        stroke="currentColor"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      ></path>
                                      <path
                                        d="M17.4406 6.23973C16.6556 6.23973 15.9796 5.68473 15.8256 4.91573L15.5826 3.69973C15.4326 3.13873 14.9246 2.75073 14.3456 2.75073H10.1126C9.53358 2.75073 9.02558 3.13873 8.87558 3.69973L8.63258 4.91573C8.47858 5.68473 7.80258 6.23973 7.01758 6.23973"
                                        stroke="currentColor"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      ></path>
                                    </svg>
                                  </span>
                                </Link>{" "} */}
                                  </div>
                                </td>
                              </tr>
                            )
                        )}
                    </tbody>
                  </table>
                </div>

                {/* for page showing */}
                <div className="row align-items-center pageNumbers">
                  <div className="col-md-6 dataPerPage">
                    <div
                      className="dataTables_info"
                      id="datatable_info"
                      role="status"
                      aria-live="polite"
                    >
                      {userList && userList.length > 0
                        ? `Showing 1 to ${userList && userList.length} entries`
                        : "No users"}
                    </div>
                  </div>
                  <div className="col-md-6 dataPerPage">
                    <div
                      className="dataTables_paginate paging_simple_numbers"
                      id="datatable_paginate"
                    >
                      <ul className="pagination">
                        {current_page > 1 ? (
                          <li
                            className="paginate_button page-item previous disabled"
                            id="datatable_previous"
                            onClick={() => loadMoreData(current_page - 1)}
                            style={{ cursor: "pointer" }}
                          >
                            <a
                              href="#"
                              aria-controls="datatable"
                              data-dt-idx="previous"
                              tabindex="0"
                              className="page-link"
                            >
                              Previous
                            </a>
                          </li>
                        ) : (
                          <li></li>
                        )}

                        {userGetTotalPage &&
                          userGetTotalPage.map((i, index) => {
                            return (
                              <li
                                className={`paginate_button page-item ${
                                  current_page === index + 1 && "active"
                                }`}
                                onClick={() => loadMoreData(index + 1)}
                              >
                                <a
                                  href="#"
                                  aria-controls="datatable"
                                  data-dt-idx="0"
                                  tabindex="0"
                                  className="page-link"
                                >
                                  {index + 1}
                                </a>
                              </li>
                            );
                          })}
                        {userList.length > 9 ? (
                          <li
                            className="paginate_button page-item next disabled"
                            id="datatable_next"
                            onClick={() => loadMoreData(current_page + 1)}
                            style={{ cursor: "pointer" }}
                          >
                            <a
                              href="#"
                              aria-controls="datatable"
                              data-dt-idx="next"
                              tabindex="0"
                              className="page-link"
                            >
                              Next
                            </a>
                          </li>
                        ) : (
                          <li></li>
                        )}
                        {/* <li
                          className="paginate_button page-item previous disabled"
                          id="datatable_previous"
                        >
                          <a
                            href="#"
                            aria-controls="datatable"
                            data-dt-idx="previous"
                            tabindex="0"
                            className="page-link"
                          >
                            Previous
                          </a>
                        </li> */}

                        {/* <li
                          className="paginate_button page-item next disabled"
                          id="datatable_next"
                        >
                          <a
                            href="#"
                            aria-controls="datatable"
                            data-dt-idx="next"
                            tabindex="0"
                            className="page-link"
                          >
                            Next
                          </a>
                        </li> */}
                      </ul>
                    </div>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <ApprovedUser
          showApprovedModal={showApprovedModal}
          setShowApprovedModal={setShowApprovedModal}
          message={`Do you want to approve ${userFirstName} ${userLastName}?`}
          approveHandler={approveHandler}
        />
      </div>
      {/* <DefaultRouter /> */}
      <Outlet />

      {/* <div className="btn-download ">
          <Button
            variant="success py-2 px-3 d-flex gap-2"
            href="https://iqonic.design/item/hope-ui-pro/item-checkout/?coupon_code=DROPBY20"
            target="_blank"
          >
            <svg
              width="22"
              className="icon-22"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                opacity="0.4"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.91064 20.5886C5.91064 19.7486 6.59064 19.0686 7.43064 19.0686C8.26064 19.0686 8.94064 19.7486 8.94064 20.5886C8.94064 21.4186 8.26064 22.0986 7.43064 22.0986C6.59064 22.0986 5.91064 21.4186 5.91064 20.5886ZM17.1606 20.5886C17.1606 19.7486 17.8406 19.0686 18.6806 19.0686C19.5106 19.0686 20.1906 19.7486 20.1906 20.5886C20.1906 21.4186 19.5106 22.0986 18.6806 22.0986C17.8406 22.0986 17.1606 21.4186 17.1606 20.5886Z"
                fill="currentColor"
              ></path>
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20.1907 6.34909C20.8007 6.34909 21.2007 6.55909 21.6007 7.01909C22.0007 7.47909 22.0707 8.13909 21.9807 8.73809L21.0307 15.2981C20.8507 16.5591 19.7707 17.4881 18.5007 17.4881H7.59074C6.26074 17.4881 5.16074 16.4681 5.05074 15.1491L4.13074 4.24809L2.62074 3.98809C2.22074 3.91809 1.94074 3.52809 2.01074 3.12809C2.08074 2.71809 2.47074 2.44809 2.88074 2.50809L5.26574 2.86809C5.60574 2.92909 5.85574 3.20809 5.88574 3.54809L6.07574 5.78809C6.10574 6.10909 6.36574 6.34909 6.68574 6.34909H20.1907ZM14.1307 11.5481H16.9007C17.3207 11.5481 17.6507 11.2081 17.6507 10.7981C17.6507 10.3781 17.3207 10.0481 16.9007 10.0481H14.1307C13.7107 10.0481 13.3807 10.3781 13.3807 10.7981C13.3807 11.2081 13.7107 11.5481 14.1307 11.5481Z"
                fill="currentColor"
              ></path>
            </svg>
            Buy Now
          </Button>
        </div> */}

      {/* <SettingOffCanvas /> */}
    </Fragment>
  );
});

Default.displayName = "Default";
export default Default;
