import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { userLogin } from "../../services/api/authApis";
import { toast } from "react-toastify";
const initialState = {
  user: null,
  isFetching: false,
  isError: false,
  isLogin: false,
  error: null,
};
export const signIn = createAsyncThunk(
  "auth/signIn",
  async (values, { rejectWithValue }) => {
    try {
      const data = await userLogin(values);
      if (data?.code !== 200) {
        // toast.error(data?.message);
        return rejectWithValue(data);
      }
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    updateUser: (state, action) => {
      const { user } = action.payload;
      state.user = user;
    },
    logOutUser: (state) => {
      state.user = null;
      state.isFetching = false;
      state.isError = false;
      state.isLogin = false;
      state.error = null;
    },
  },
  extraReducers: {
    [signIn.pending]: (state) => {
      state.isFetching = true;
      state.isLogin = false;
      state.isError = false;
      return state;
    },
    [signIn.fulfilled]: (state, { payload }) => {
      state.isLogin = true;
      state.user = { ...payload?.data?.user };
      state.isFetching = false;
      state.isError = false;
      state.error = null;
      return state;
    },
    [signIn.rejected]: (state, { payload }) => {
      state.isLogin = false;
      state.user = null;
      state.isFetching = false;
      state.isError = true;
      state.error = payload;
      return state;
    },
  },
});

// this is for dispatch
export const { updateUser, logOutUser } = authSlice.actions;

// this is for configureStore
export default authSlice.reducer;
