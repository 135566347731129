import * as Yup from "yup";

export const ResponseValidator = async ({ isError, message }) => {
  const validator = Yup.object().shape({
    isError: Yup.bool(),
    message: Yup.string(),
  });
  try {
    return await validator.isValid({
      isError: isError,
      message: message,
    });
    // return validate
  } catch (err) {
    console.log(err);
  }
};
