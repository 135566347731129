import React from "react";
import { Route, Routes } from "react-router-dom";
import SignInContents from "./signInContents";
import OtpContents from "./otpContents";

const SignInSection = () => {
  return (
    <Routes>
      <Route path="/signIn" element={<SignInContents />} />
      <Route path="/otp" element={<OtpContents />} />
    </Routes>
  );
};

export default SignInSection;
