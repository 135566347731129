import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  deleteService,
  getAllService,
} from "../../../../services/api/dynamicDataSectionVise";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import DeleteService from "../../../../components/common/deleteService/deleteService";
import { toast } from "react-toastify";

const AllServices = () => {
  const navigate = useNavigate();
  const [allServicesData, setAllServicesData] = useState([]);
  const [showDeleteServiceModal, setShowDeleteServiceModal] = useState(false);
  const [serviceIdDelete, setServiceIdDelete] = useState("");
  const [sectionIdDelete, setSectionIdDelete] = useState("");

  useEffect(() => {
    (async () => {
      let data = await getAllService("en");
      setAllServicesData(data?.data?.services);
    })();
    return () => setAllServicesData();
  }, []);
  const deleteHandler = async () => {
    const getDeletedata = await deleteService(serviceIdDelete, sectionIdDelete);
    if (getDeletedata) {
      let newAllServicesData = allServicesData.filter((item) => {
        if (item.id !== serviceIdDelete) {
          return item;
        }
      });
      setAllServicesData(newAllServicesData);
      setShowDeleteServiceModal(false);
      setServiceIdDelete("");
      setSectionIdDelete("");
      toast.success(getDeletedata?.message);
    }
  };
  const openDeleteModel = (serviceId, sectionId) => {
    setServiceIdDelete(serviceId);
    setSectionIdDelete(sectionId);
    setShowDeleteServiceModal(true);
  };
  return (
    <>
      <div className="row row-cols-1 row-cols-md-3 g-4">
        {allServicesData &&
          allServicesData.map((val, idx) => {
            return (
              <div className="col">
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-title">
                      <div className="button-container">
                        <FontAwesomeIcon
                          className="custom-button"
                          onClick={() =>
                            navigate(
                              "/dashboard/app/service/all_services/create_service",
                              {
                                state: {
                                  type: "edit",
                                  section_id: val?.section_id,
                                },
                              }
                            )
                          }
                          icon={faEdit}
                        />
                        <FontAwesomeIcon
                          className="custom-button"
                          onClick={() =>
                            openDeleteModel(val?.id, val?.section_id)
                          }
                          icon={faTrashAlt}
                        />
                      </div>
                      <div></div>

                      {val?.title}
                    </h5>
                    {/* <p className="card-text">{val?.link}</p>
                    <p
                      className="card-text"
                      dangerouslySetInnerHTML={{ __html: `${val?.details}` }}
                    ></p> */}
                  </div>
                </div>
              </div>
            );
          })}
        <div className="col">
          <div
            className="card"
            onClick={() =>
              navigate("/dashboard/app/service/all_services/create_service", {
                state: { type: "add" },
              })
            }
          >
            <div className="card-body plusSign">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                className="plus-icon"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M12 4v16m8-8H4"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
      <DeleteService
        showDeleteServiceModal={showDeleteServiceModal}
        setShowDeleteServiceModal={setShowDeleteServiceModal}
        message={"Are you sure you want to delete"}
        deleteHandler={deleteHandler}
      />
    </>
  );
};

export default AllServices;
