import { toast } from "react-toastify";
import http from "./http";

export const getUserList = async (value) => {
  let userListGet = await http.post(`/admin/user/get-users`, value);
  if (userListGet?.code === 200) {
    return userListGet;
  }
};
export const approvedUserList = async (id) => {
  let approvedUserListGet = await http.patch(`/admin/user/approve-user/${id}`);
  if (approvedUserListGet?.code === 200) {
    return approvedUserListGet;
  }
};
export const getUserDetails = async (id) => {
  let getUserDetailsData = await http.get(`/admin/user/get-user-details/${id}`);
  if (getUserDetailsData?.code === 200) {
    return getUserDetailsData;
  }
};
