import catchAsync from "../../utils/catchAsync";
import { toast } from "react-toastify";
import http from "./http";
import { logOutUser } from "../../store/reducers/authSlice";

export const refreshLoggedInUser = catchAsync(async (refresh_token) => {
  return http
    .post("/admin/auth/refresh-tokens", refresh_token)
    .then((tokenData) => {
      const data = tokenData;
      if (data && data.code === 200) {
        setSession(data.data.tokens);
        return data.data.tokens;
      }
    })
    .catch((error) => {
      userLogout();
      window.location.reload();
      return false;
    });
});

/**
 * *Set Session Fot User
 * @param {object} tokens
 */
const setSession = (tokens) => {
  if (tokens) {
    const { access, refresh } = tokens;
    if (access && refresh) {
      sessionStorage.setItem("_t", access.token);
      sessionStorage.setItem("_rt", refresh.token);
      // sessionStorage.setItem("_e", addDateTime(new Date(), "minute", 29));
    }
  } else {
    sessionStorage.removeItem("_t");
    sessionStorage.removeItem("_rt");
    sessionStorage.removeItem("_e");
  }
};

/**
 * *User login API.
 * @function ({{email: string, password: string}} values)
 * @returns {object}
 */
export const userLogin = catchAsync(async (values) => {
  const data = await http.post("/admin/auth/verify-login-otp", values);
  if (data && data.code === 200) {
    setSession(data?.data.tokens);
  }
  return data;
});
export const userLoginVerifyEmail = async (values) => {
  const data = await http.post("/admin/auth/sign-in", values);
  if (data && data.code === 200) {
    return data;
  }
};
/**
 * Forgot passeord get otp via email address
 * @body {email:string}
 */
export const forgotPassword = async (values) => {
  let otpResendData = await http.post("/admin/auth/forgot-password", values);
  if (otpResendData?.code === 200) {
    return otpResendData;
  } else {
    toast.error(otpResendData?.message);
  }
};
/**
 * Email verification otp resend
 */
// export const resendOtp = async (email) => {
//     let otpResendData = await http.post("/profile/resend/otp", { email: email });
//     if (otpResendData?.serverResponse.code === 200) {
//         toast.success(otpResendData?.serverResponse.message)
//     }
//     else {
//         toast.error(otpResendData?.serverResponse.message)
//     }
// }
// /**
//  * Email verification
//  */
export const verifyEmail = async (values) => {
  // let values = { otp: otp };
  let verifyResult = await http.post(
    "/admin/auth/verify-reset-password-otp",
    values
  );
  if (verifyResult?.code === 200) {
    return verifyResult;
  } else {
    toast.error(verifyResult?.message);
  }
};
//reset password
export const resetPassword = async (values) => {
  let resetPasswordData = await http.post("/admin/auth/reset-password", values);
  if (resetPasswordData?.code === 200) {
    toast.success(resetPasswordData?.message);
    return resetPasswordData;
  } else {
    toast.error(resetPasswordData?.message);
  }
};
// user logout functio
export const userLogout = (dispatch) => {
  if (dispatch) {
    dispatch(logOutUser());
  }
  setSession(null);
  clearStorage();
};
/**
 * Clear local storage
 */
const clearStorage = () => {
  localStorage.removeItem("persist:bi-reports-admin");
};
