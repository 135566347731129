import React from "react";
import HomeBanner from "./homeBanner";
import { Route, Routes } from "react-router-dom";
import AboutUs from "./aboutUs";
import WhyChooseUs from "./whyChooseUs";
import Services from "./services";
import Testimonials from "./testimonials";

const Home = () => {
  return (
    <Routes>
      <Route path="/banner" element={<HomeBanner />} />
      <Route path="/about_us" element={<AboutUs />} />
      <Route path="/why_choose_us" element={<WhyChooseUs />} />
      <Route path="/services" element={<Services />} />
      <Route path="/testimonials" element={<Testimonials />} />
    </Routes>
  );
};

export default Home;
