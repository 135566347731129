import React from 'react'
import { Route, Routes } from 'react-router-dom'
import ResetPasswordContents from './resetPasswordContents'
import VerifyEmailContents from './verifyEmailContents'
import ForgotPasswordContents from './forgotPasswordContents'


const ForgotPassSection = () => {
  return (
    <Routes>
    <Route path="/reset_password" element={<ResetPasswordContents />} />
    <Route path="/verify_email" element={<VerifyEmailContents />} />
    <Route path="/forgot_password" element={<ForgotPasswordContents />} />

  </Routes>
  )
}

export default ForgotPassSection