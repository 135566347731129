import { useEffect, useState } from "react";
import { dynamicDataSectionVise } from "../../../../services/api/dynamicDataSectionVise";
import CommonForm from "../commonForm";

const AboutUs = () => {
  const [data, setData] = useState();

  useEffect(() => {
    gettingDynamicData();
  }, []);
  const gettingDynamicData = async () => {
    let dataGet = await dynamicDataSectionVise("cms_section_home_about");
    setData(dataGet);
  };
  return (
    <>
      <CommonForm sectionId={"cms_section_home_about"} data={data} />
    </>
  );
};

export default AboutUs;
